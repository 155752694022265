import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {Box, Collapse, LinearProgress} from '@mui/material'
import currencyFormatter from 'currency-formatter'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {FieldValue} from '../../../Molecules/FieldDetails'
import {useFeaturesState} from '../../Features'
import {NotYetInvoicedCashBalance} from '../Cashbalance/CashBalance.types'
import {CashBalance} from '../CashBalance.types'

import {CashBalanceProgressBarContainer, CashBalanceProgressBarItem} from './CashBalanceProgressBar'
import {InfoBracketContainer} from './InfoBracketContainer'
import {SubTitleWithDropdown} from './SubTitleWithDropdown'

interface Type {
  cashBalances?: CashBalance[]
  payerName?: string
  loading?: boolean
  smallScreen?: boolean
  collapse?: boolean
  onDark?: boolean
}

export const MinifiedCashBalanceWidget: React.FC<Type> = ({
  cashBalances,
  loading,
  payerName,
  smallScreen,
  collapse,
  onDark = false
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {getFeature} = useFeaturesState()
  const [collapsed, setCollapsed] = React.useState<boolean>(false)
  const [filteredCashBalance, setFilteredCashBalance] = React.useState<CashBalance | undefined>(
    cashBalances && cashBalances[0]
  )
  const handleCollapsed = () => {
    if (collapsed) {
      setCollapsed(false)
    } else {
      setCollapsed(true)
    }
  }

  React.useEffect(() => {
    if (collapse !== undefined) {
      setCollapsed(collapse)
    }
  }, [collapse])

  React.useEffect(() => {
    if (!loading && cashBalances) {
      setFilteredCashBalance(cashBalances[0])
    }
  }, [loading, cashBalances])

  if (!filteredCashBalance) {
    return null
  }

  const isSplittedValueEnabled = getFeature('CashBalanceDetailedNotYetInvoiced')

  const {
    currency,
    receivables,
    salesValue,
    remainingCredit,
    total,
    exceededLimit,
    creditLimit,
    deposit,
    deliveredNotBilled = 0,
    orderedNotDelivered = 0
  } = filteredCashBalance
  const notYetInvoiced: NotYetInvoicedCashBalance = isSplittedValueEnabled
    ? {
        amount: deliveredNotBilled,
        subtitle: t('overview.cashBalance.dispatched')
      }
    : {
        amount: salesValue,
        subtitle: null
      }
  const notYetInvoicedNotYetDispatched: NotYetInvoicedCashBalance | null = isSplittedValueEnabled
    ? {
        amount: orderedNotDelivered,
        subtitle: t('overview.cashBalance.notYetDispatched')
      }
    : null

  const getBarItems: any = () => {
    return (
      <>
        <CashBalanceProgressBarItem
          variant="unpaid"
          amount={receivables}
          totalAmount={total}
          minified
        />
        <CashBalanceProgressBarItem
          variant="notYetInvoiced"
          amount={notYetInvoiced.amount}
          totalAmount={total}
          minified
        />
        {notYetInvoicedNotYetDispatched ? (
          <CashBalanceProgressBarItem
            variant="notYetInvoicedNotYetDispatched"
            amount={notYetInvoicedNotYetDispatched.amount}
            totalAmount={total}
            minified
            dataTestId="minified-not-yet-invoiced-not-yet-dispatched-progress-bar"
          />
        ) : null}
      </>
    )
  }
  const getCashBalanceNumbers: any = () => {
    return (
      <>
        <Box
          data-test-id="minified-cash-balance-numbers"
          mt={4}
          display="flex"
          flexDirection={smallScreen ? 'row' : 'column'}
          flexWrap="wrap"
          gap={2}
        >
          <Box data-test-id="minified-cash-unpaid">
            <FieldValue
              borderLeftHtmlColor="#67A1E1"
              label={t('overview.cashBalance.unpaid')}
              dataTestId="minified-cash-unpaid-field"
              value={currencyFormatter.format(receivables, {code: currency, locale: language})}
            />
          </Box>
          <Box data-test-id="minified-not-yet-invoiced-cash-info-box">
            <FieldValue
              borderLeftHtmlColor="#7DD67E"
              label={t('overview.cashBalance.notYetInvoiced')}
              dataTestIdSubtitle="minified-not-yet-invoiced-subtitle"
              value={currencyFormatter.format(notYetInvoiced.amount, {
                code: currency,
                locale: language
              })}
              subtitle={notYetInvoiced.subtitle}
            />
          </Box>
          {notYetInvoicedNotYetDispatched ? (
            <Box data-test-id="minified-not-yet-invoiced-not-yet-dispatched-cash-info-box">
              <FieldValue
                borderLeftHtmlColor="#6D1D82"
                label={t('overview.cashBalance.notYetInvoiced')}
                dataTestIdSubtitle="minified-not-yet-invoiced-not-yet-dispatched-subtitle"
                value={currencyFormatter.format(notYetInvoicedNotYetDispatched.amount, {
                  code: currency,
                  locale: language
                })}
                subtitle={notYetInvoicedNotYetDispatched.subtitle}
              />
            </Box>
          ) : null}
          <Box data-test-id="minified-cash-left">
            <FieldValue
              borderLeftHtmlColor="#000"
              label={t('overview.cashBalance.balanceLeft')}
              dataTestId="minified-cash-left-field"
              value={currencyFormatter.format(remainingCredit < 0 ? 0 : remainingCredit, {
                code: currency,
                locale: language
              })}
            />
            {exceededLimit > 0 ? (
              <Box data-test-id="minified-cash-exceeded" color="#CC0801" ml={1.5} fontSize={12}>
                {t('overview.cashBalance.exceededBy')}{' '}
                {currencyFormatter.format(exceededLimit, {
                  code: currency,
                  locale: language
                })}
              </Box>
            ) : null}
          </Box>
        </Box>
      </>
    )
  }
  return (
    <Box
      data-test-id="minified-cash-balance-widget"
      display="flex"
      flexDirection="column"
      minWidth="100%"
      px={1}
    >
      <Box data-test-id="minified-cash-balance-summary">
        <Box data-test-id="minified-cash-title" display="flex" justifyContent="space-between">
          {cashBalances && cashBalances.length === 1 ? (
            <FieldValue
              dataTestId="minified-cash-summary-title"
              label={smallScreen ? t('finance.summary') : t('overview.cashBalance.summaryFor')}
              value={payerName}
            />
          ) : (
            <SubTitleWithDropdown
              smallScreen={smallScreen}
              dark={false}
              onLight
              onChange={(balance: CashBalance) => setFilteredCashBalance(balance)}
              options={cashBalances}
              selectedItem={filteredCashBalance}
            />
          )}
          {smallScreen ? (
            <Box onClick={handleCollapsed}>
              {collapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </Box>
          ) : null}
        </Box>
        <Box data-test-id="minified-cash-balance-line" mt={2}>
          {loading ? (
            <LinearProgress data-test-id="widget-cash-balance-loader" />
          ) : (
            <>
              {creditLimit > 0 ? (
                <Collapse in={!collapsed}>
                  <InfoBracketContainer
                    creditLimit={creditLimit}
                    openAmount={receivables}
                    deposit={deposit}
                    overDraft={exceededLimit}
                    currency={currency}
                    minified={false}
                    onDark={onDark}
                    responsive={true}
                  />
                  <Box p={0.25} />
                </Collapse>
              ) : null}
              <CashBalanceProgressBarContainer minified>
                {getBarItems()}
              </CashBalanceProgressBarContainer>
            </>
          )}
        </Box>
      </Box>
      <Collapse in={!collapsed}>{getCashBalanceNumbers()}</Collapse>
    </Box>
  )
}
