import {NavItem} from '@hconnect/uikit/src/lib/Shell/'
import {TFunction} from 'i18next'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

export interface Features {
  countries?: string[]
  orgUnits?: string[]
}

export enum FlagType {
  Blacklist = 'blacklist',
  Whitelist = 'whitelist'
}
export enum FeatureFlagEntity {
  Countries = 'countries',
  OrgUnits = 'orgUnits'
}
export interface FeatureFlags {
  blacklist?: Features
  whitelist?: Features
}

interface HCRoutes {
  component: any
  featureToggleName: string
  label: string
  labelKey: string
  order: number
  path: string
  permissions: string[]
  routes: Record<string, HCRoutes>
}

export interface PermittedRoutes {
  path: string
  routes: Record<string, HCRoutes>
}

export const checkFeatureFlags = (
  type: FlagType,
  featureEntity: FeatureFlagEntity,
  featureFlags?: FeatureFlags,
  currentFlags?: FeatureFlags
): boolean => {
  if (featureFlags && currentFlags) {
    const featureFlag = featureFlags[type]
    const currentFeatureFlag = currentFlags[type]
    if (featureFlag && currentFeatureFlag) {
      const featureFlagged = featureFlag[featureEntity] || []
      const currentFlagged = currentFeatureFlag[featureEntity] || []
      return featureFlagged.some((feat: string) => currentFlagged.includes(feat))
    }
  }
  return false
}

// eslint-disable-next-line complexity
export const getPermittedRouteByPath = (
  path: string,
  grantedPermissionTypes: Set<string>,
  featureFlags: FeatureFlags,
  roles: string[],
  isFeatureToggleEnabled: (featureName: string | string[]) => boolean,
  getRouteByPath: (path: string) => any,
  checkFeatureFlags: (
    type: FlagType,
    featureEntity: FeatureFlagEntity,
    featureFlags?: FeatureFlags | undefined,
    currentFlags?: FeatureFlags | undefined
  ) => boolean,
  ignoreHideInNav = false,
  enabledByApi = false
): PermittedRoutes | null => {
  const baseRoute = getRouteByPath(path)

  if (baseRoute) {
    const routes = {}

    for (const key of Object.keys(baseRoute.routes)) {
      const subRoute = baseRoute.routes[key]

      const isDetailPage = key.includes(':')

      const shouldRouteBeVisible = ignoreHideInNav ? true : !subRoute.hideInMainNav
      const isCountryAllowed = !checkFeatureFlags(
        FlagType.Blacklist,
        FeatureFlagEntity.Countries,
        featureFlags,
        get(subRoute, 'featureFlags')
      )
      const isRouteAllowed =
        !Array.isArray(subRoute.permissions) ||
        subRoute.permissions.length === 0 ||
        subRoute.permissions.some((p) => grantedPermissionTypes.has(p))

      const isRolesAllowed = !subRoute.roles || subRoute.roles.some((role) => roles.includes(role))
      const isFeatureEnabled = isFeatureToggleEnabled(subRoute.featureToggleName)

      const isEnabledByApi = subRoute.checkByApi ? enabledByApi : true

      const rolesDisabled = subRoute.rolesDisabled?.some((role) => roles.includes(role))

      if (
        shouldRouteBeVisible &&
        isRouteAllowed &&
        isCountryAllowed &&
        isRolesAllowed &&
        isFeatureEnabled &&
        !isDetailPage &&
        isEnabledByApi &&
        !rolesDisabled
      ) {
        routes[key] = subRoute
      }
    }

    return {
      ...baseRoute,
      routes
    }
  }
  return null
}

export const mapNavLinksToBurgerMenu = (
  path: string,
  grantedPermissionTypes: Set<string>,
  featureFlags: FeatureFlags,
  roles: string[],
  isFeatureToggleEnabled: (featureName: string | string[]) => boolean,
  t: TFunction,
  getRouteByPath: (path: string) => any,
  checkFeatureFlags: (
    type: FlagType,
    featureEntity: FeatureFlagEntity,
    featureFlags?: FeatureFlags | undefined,
    currentFlags?: FeatureFlags | undefined
  ) => boolean,
  getPermittedRouteByPath: (
    path: string,
    grantedPermissionTypes: Set<string>,
    featureFlags: FeatureFlags,
    roles: string[],
    isFeatureToggleEnabled: (featureName: string | string[]) => boolean,
    getRouteByPath: (path: string) => any,
    checkFeatureFlags: (
      type: FlagType,
      featureEntity: FeatureFlagEntity,
      featureFlags?: FeatureFlags | undefined,
      currentFlags?: FeatureFlags | undefined
    ) => boolean,
    ignoreHideInNav?: boolean,
    enabledByApi?: boolean
  ) => PermittedRoutes | null,
  ignoreHideInNav = false,
  enabledByApi = false
): NavItem[] => {
  const permittedRoutes = getPermittedRouteByPath(
    path,
    grantedPermissionTypes,
    featureFlags,
    roles,
    isFeatureToggleEnabled,
    getRouteByPath,
    checkFeatureFlags,
    ignoreHideInNav,
    enabledByApi
  )
  if (!permittedRoutes) {
    return []
  }
  return Object.values(permittedRoutes.routes)
    .sort((a, b) => a.order - b.order)
    .map((route) => {
      let subItems: NavItem[] = []
      if (route.routes && !isEmpty(route.routes)) {
        subItems = mapNavLinksToBurgerMenu(
          route.path,
          grantedPermissionTypes,
          featureFlags,
          roles,
          isFeatureToggleEnabled,
          t,
          getRouteByPath,
          checkFeatureFlags,
          getPermittedRouteByPath,
          true
        )
      }

      return {
        label: t(`${route.labelKey}`),
        url: route.path,
        dataTestId: `menu-${route.labelKey}`,
        subItems
      }
    })
}
