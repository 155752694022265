/* eslint-disable complexity */
import {HCThemeType, Typography, MOMENTJS_DATE_FORMAT, SingleDatePicker} from '@hconnect/uikit'
import {Grid, makeStyles} from '@material-ui/core'
import {isAfter} from 'date-fns'
import moment from 'moment'
import React, {useEffect, useRef} from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {TimePicker} from '../../../../OrderIntake/components/TimePicker'
import {
  DATA_BASE_TIME_FORMAT,
  evaluateDeliveryTime
} from '../../../../OrderIntake/components/TimeScroller/TimeScroller.utils'
import {useFeaturesState} from '../../../../Organisms/Features'
import {
  getTodaysCutOffTime,
  getUnavailableDaysFromTimeSlots,
  isCurrentTimeAfterCutOff,
  mergeDateRanges
} from '../../../../Organisms/OrderIntake/utils'
import {useHasRoleByCustomerId} from '../../../../Roles'
import {createTimeInterval, getTzAwareNow, isDateToday} from '../../../../util/time'
import {
  BUSINESS_HOURS_EARLIEST_DEFAULT,
  BUSINESS_HOURS_LATEST_DEFAULT,
  BUSINESS_HOURS_MIN_FIX_INTERVAL_TIME,
  BUSINESS_HOURS_MIN_INTERVAL_DEFAULT
} from '../../../declarations/constants'
import {ShippingType} from '../../../declarations/OrderIntake.enums'
import {
  ActiveMaterialDeliveryItem,
  OrderIntakeMaterialOptionPayload,
  OrderIntakeOption,
  OrderRequest,
  SlotConfiguration,
  TimeSelectorTrackingEventType
} from '../../../declarations/types'
import {useOrderIntakeData} from '../../../hooks'
import {getCustomMessageForDeliverDate} from '../../utils'
import {getDateExceptions} from '../DateFilter'

import testIds from './dataTestIds'
import {MaterialOrderForm} from './types'
import {validateDeliveryDates} from './utils'

const MINUTE_STEP = 30

const useStyles = makeStyles((theme: HCThemeType) => ({
  contentItem: {
    paddingBottom: theme.spacing(3)
  }
}))

type Props = {
  selectedSite: OrderIntakeOption
  slotConfigurations?: SlotConfiguration[]
  order: OrderRequest
  defaultMaterialOption: OrderIntakeMaterialOptionPayload
  initiallyOpenedItem?: ActiveMaterialDeliveryItem
  shippingType: ShippingType
  onTimeSelectorTrack?: (eventType: TimeSelectorTrackingEventType) => void
}

export const DateTimeTab: React.FC<Props> = ({
  selectedSite,
  slotConfigurations,
  order,
  defaultMaterialOption,
  shippingType,
  onTimeSelectorTrack
}) => {
  const firstUpdate = useRef(true)
  const {t} = useTranslation()
  const c = useStyles()
  const methods = useFormContext<MaterialOrderForm>()
  const {getFeature} = useFeaturesState()
  const {customerId} = useOrderIntakeData()
  const {data: isOrderPlacerFixtime} = useHasRoleByCustomerId(customerId, 'ORDER_PLACER_FIXTIME')
  const hasFixedTime = isOrderPlacerFixtime && shippingType === ShippingType.DELIVER
  const businessHours = defaultMaterialOption.businessHours
  const tz = businessHours?.timeZone ?? ''

  const deliveryDateRange = mergeDateRanges(
    (selectedSite.materials[order.payload.materialEnteredNumber] ?? []).map(
      (material) => material.dateRange
    )
  )

  const businessDays = deliveryDateRange?.businessDays ? deliveryDateRange?.businessDays : []
  const todaysCutOffTime = getTodaysCutOffTime(businessDays)[0].cutOffTime
  const cutOffTimeStamp = todaysCutOffTime?.timestamp

  const isAfterCutoffTime = isCurrentTimeAfterCutOff(cutOffTimeStamp, tz)
  const customMessage = getCustomMessageForDeliverDate(deliveryDateRange, t)
  const earliestPossible = businessHours?.earliestPossible ?? BUSINESS_HOURS_EARLIEST_DEFAULT
  const latestPossible = businessHours?.latestPossible ?? BUSINESS_HOURS_LATEST_DEFAULT
  const minInterval = businessHours?.minInterval ?? BUSINESS_HOURS_MIN_INTERVAL_DEFAULT
  const minFixIntervalTime =
    businessHours?.minFixIntervalTime !== '00:00:00' || businessHours?.minFixIntervalTime
      ? businessHours?.minFixIntervalTime
      : BUSINESS_HOURS_MIN_FIX_INTERVAL_TIME
  const timeChangeInterval = !Number.isNaN(Number(businessHours.timeChangeInterval))
    ? Number(businessHours.timeChangeInterval)
    : MINUTE_STEP

  const deliveryDate = methods.watch('dateTime.deliveryDate')

  const isSlotManagementEnabled = getFeature(
    shippingType === ShippingType.DELIVER ? 'OrderIntakeSlotsDeliver' : 'OrderIntakeSlotsCollect'
  )

  useEffect(() => {
    // we need to run this after first render when date changes
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    const materialOptions = selectedSite.materials[order.payload.materialEnteredNumber]

    const configurableSlot = isSlotManagementEnabled
      ? materialOptions[0].plant.configurableSlots?.filter((s) => s.date === deliveryDate)
      : []

    const evaluateArgs = [
      minInterval,
      DATA_BASE_TIME_FORMAT,
      deliveryDate,
      materialOptions[0].defaultDeliveryWindow,
      materialOptions[0].businessHours,
      materialOptions[0].businessHours.timeZone,
      !getFeature('OrderIntakeCollectTimePicker')
    ] as const

    if (isSlotManagementEnabled) {
      const deliveryTime = evaluateDeliveryTime(
        ...evaluateArgs,
        slotConfigurations,
        configurableSlot
      )

      methods.setValue(
        'dateTime.deliveryTime',
        {
          earliest: deliveryTime.earliest,
          latest: deliveryTime.latest
        },
        {shouldDirty: true}
      )
    }

    methods.clearErrors('dateTime')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryDate, order.payload.materialEnteredNumber, selectedSite.materials, shippingType])

  const timeSelectorTrack = (eventType: TimeSelectorTrackingEventType) => {
    onTimeSelectorTrack && onTimeSelectorTrack(eventType)
  }

  const onTimePickerError = (isError: boolean) => {
    isError
      ? methods.setError('dateTime', {type: 'custom', message: ''})
      : methods.clearErrors('dateTime')
  }

  const materialOptions = selectedSite.materials[order.payload.materialEnteredNumber]

  const showDatePicker =
    getFeature('OrderIntakeCollectTimePicker') || shippingType === ShippingType.DELIVER
  const fullDays = isSlotManagementEnabled
    ? getUnavailableDaysFromTimeSlots(
        businessHours,
        slotConfigurations,
        materialOptions[0].plant.configurableSlots
      ).map((s) => moment(s))
    : []

  return (
    <Grid>
      <div className={c.contentItem}>
        <Typography variant="caption" color="secondary">
          {shippingType === ShippingType.DELIVER
            ? t('orderIntake.materialOrder.shipTo')
            : t('orderIntake.materialOrder.location')}
        </Typography>
        <Typography variant="h4" color="textPrimary" data-test-id={testIds.dateTime.shipTo}>
          {defaultMaterialOption.shippingAddress.siteName}
          {tz && ` (${moment.tz(tz ?? '').zoneAbbr()})`}
        </Typography>
      </div>
      <Grid className={c.contentItem}>
        <Controller
          control={methods.control}
          name="dateTime.deliveryDate"
          render={({field}) => {
            return (
              <SingleDatePicker
                onLight
                noQuickSelection
                data-test-id={testIds.dateTime.datePicker}
                fullWidth
                showTooltip={order.isDateChange}
                tooltipLabel={t('orderIntake.dateChanged')}
                customMessage={customMessage}
                date={moment(field.value)}
                handleDateChange={({startDate}) => {
                  field.onChange(moment(startDate).format(MOMENTJS_DATE_FORMAT))
                }}
                availableDateRange={{
                  from: isAfterCutoffTime
                    ? moment(deliveryDateRange.fromAfterCutoff)
                    : moment(deliveryDateRange.from),
                  to: isAfterCutoffTime
                    ? moment(deliveryDateRange.toAfterCutoff)
                    : moment(deliveryDateRange.to)
                }}
                dateExceptions={[
                  ...getDateExceptions(
                    selectedSite.materials[order.payload.materialEnteredNumber][0].businessHours,
                    deliveryDateRange
                  ),
                  ...fullDays
                ]}
              />
            )
          }}
        />
      </Grid>
      {showDatePicker && (
        <Grid className={c.contentItem} data-test-id={testIds.dateTime.timePicker}>
          <Controller
            control={methods.control}
            name={'dateTime.deliveryTime'}
            rules={{
              validate: (value) => validateDeliveryDates(value, deliveryDate, businessHours)
            }}
            render={({field}) => {
              return (
                <TimePicker
                  isSlotManagementEnabled={isSlotManagementEnabled}
                  onError={onTimePickerError}
                  hasFixedTime={hasFixedTime}
                  name={field.name}
                  value={field.value}
                  deliveryDate={deliveryDate}
                  minInterval={minInterval}
                  onChange={field.onChange}
                  timezone={tz}
                  data-test-id={testIds.dateTime.timePicker}
                  configurableSlots={materialOptions[0].plant.configurableSlots?.filter(
                    (s) => s.date === deliveryDate
                  )}
                  businessHours={businessHours}
                  defaultDeliveryWindow={defaultMaterialOption.defaultDeliveryWindow}
                  slotsConfiguration={slotConfigurations}
                  differentTimeZoneWarning={t(
                    shippingType === ShippingType.DELIVER
                      ? 'orderIntake.supplyingDestinationDifferent'
                      : 'orderIntake.supplyingPlantDifferent'
                  )}
                  interval={createTimeInterval(deliveryDate, earliestPossible, latestPossible)}
                  minFixIntervalTime={minFixIntervalTime}
                  timeChangeInterval={timeChangeInterval}
                  filterTimeSlots={(d: Date) =>
                    !(isDateToday(deliveryDate, tz) && isAfter(getTzAwareNow(tz), d))
                  }
                  onTimeSelectorTrack={timeSelectorTrack}
                  cardId={order.payload.materialNumber}
                />
              )
            }}
          />
        </Grid>
      )}
    </Grid>
  )
}
