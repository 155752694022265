import {Overlay} from '@hconnect/uikit/src/lib/Shell/Overlay'
import {Box, Divider, MenuItem, MenuList, Typography} from '@material-ui/core'
import LogoutIcon from '@mui/icons-material/Logout'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import classNames from 'classnames'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {Customer} from '../../Organisms/Customers/Customers.types'
import {CustomerPanelProfile} from '../CustomerFeedbackProgramme/CustomerFeedbackProfile/CustomerPanelProfile'

import {AccountButton} from './AccountButton'
import {useAccountMenuStyles} from './AccountMenuStyles'
import {AccountsMenu} from './AccountsMenu'
import {LanguageMenu} from './LanguageMenu'

export type Locales = {
  defaultLocale: string
  locales: {code: string; name: string}[]
}
type AccountMenuProps = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  name: string
  email: string
  customers: Customer[]
  locales: Locales
  selectLanguage: (language: string) => void
  logout: () => void
  mhcUrl?: string
}

enum UserIconColor {
  orange = 'orange',
  purple = 'purple',
  green = 'green',
  blue = 'blue'
}

export const AccountMenu: React.FC<AccountMenuProps> = ({
  isOpen,
  setOpen,
  name,
  email,
  customers,
  locales,
  selectLanguage,
  logout,
  mhcUrl
}) => {
  const classes = useAccountMenuStyles()
  const {t} = useTranslation()
  let color
  switch (email.length % 4) {
    case 0:
      color = UserIconColor.orange
      break
    case 1:
      color = UserIconColor.purple
      break
    case 2:
      color = UserIconColor.green
      break
    case 3:
      color = UserIconColor.blue
  }
  const [subMenu, setSubMenu] = useState<JSX.Element | null>(null)
  const getInitials = (name: string): string => {
    const names = name.split(' ')
    const initials = names.length > 1 ? names[0][0] + names[names.length - 1][0] : names[0][0]
    return initials.toLocaleUpperCase()
  }

  const userIcon = <Typography variant="h4">{getInitials(name)}</Typography>

  return (
    <>
      <AccountButton
        open={isOpen}
        onClick={() => {
          setOpen(!isOpen)
          setSubMenu(null)
        }}
        className={classNames({[classes[color]]: !isOpen}, classes.accountButton)}
        userIcon={userIcon}
      />
      <Overlay
        data-test-id="account-menu-overlay"
        isVisible={isOpen}
        overlayClassName={classes.overlay}
        contentClassName={classNames(classes.overlayContent, {
          [classes.subMenuOpen]: subMenu !== null
        })}
      >
        <MenuList className={classes.menu}>
          <div>
            <Box className={classes.item}>
              <Box className={classNames(classes.userIcon, classes[color])}>
                {getInitials(name)}
              </Box>
              <Typography className={classes.name}>{name}</Typography>
              <Typography className={classes.email}>{email}</Typography>
            </Box>
            <Divider className={classes.divider} />
            <AccountsMenu
              accounts={customers
                .filter((customer) => typeof customer.customerNumber === 'string')
                .map((customer) => String(customer.customerNumber))}
              openSubMenu={setSubMenu}
              data-test-id="account-accountsMenu"
            />
            <Divider className={classes.divider} />
            <CustomerPanelProfile />
            <Divider className={classes.divider} />
            <LanguageMenu
              languages={locales}
              selectLanguage={selectLanguage}
              openSubMenu={setSubMenu}
              data-test-id="account-languageMenu"
            />
            <Divider className={classes.divider} />
            {mhcUrl ? (
              <>
                <MenuItem
                  className={classes.item}
                  component="a"
                  href={mhcUrl}
                  data-test-id="account-userAdminLink"
                >
                  <Typography>{t('header.userAdmin')}</Typography>
                  <OpenInNewIcon className={classNames(classes.iconRight)} />
                </MenuItem>
                <Divider className={classes.divider} />
              </>
            ) : null}
            <MenuItem className={classes.item} onClick={logout} data-test-id="account-logout">
              <LogoutIcon className={classNames(classes.icon, classes.logoutIcon)} />
              <Typography>{t('header.logOut')}</Typography>
            </MenuItem>
          </div>
        </MenuList>
        {subMenu}
      </Overlay>
    </>
  )
}
