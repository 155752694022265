import {connect} from 'react-redux'

import {sheetRequest} from '../../../AsyncJobQueue/AsyncJobQueue.action'
import {selectSelectedCustomerFilter} from '../../../Organisms/Customers'
import {downloadDocument} from '../../../Organisms/Documents'
import {errorsClear, selectErrorByKey} from '../../../Organisms/Errors'
import {loadMore, selectMaterialTests, setSortKey} from '../../../Organisms/Materialtest'
import {AppState} from '../../../Root.store'

import {Rmc} from './MaterialTest'
import {fetchMaterialTests, switchView} from './MaterialTest.action'
import {MaterialTestView} from './MaterialTest.enum'
import {
  LookupFilterType,
  selectDateFilter,
  setDefaultDateFilter,
  setLookupFilter
} from './MaterialTest.filters'
import {selectGroupedTestsByOrderID, selectViewCurrent} from './MaterialTest.selector'

const mapStateToProps = (state: AppState) => ({
  items: selectGroupedTestsByOrderID(state),
  view: selectViewCurrent(state),
  filters: selectMaterialTests(state).filters,
  defaultFilters: selectMaterialTests(state).defaultFilters,
  dateFilter: selectDateFilter(state),
  sortOrder: selectMaterialTests(state).sortOrder,
  isFetching: selectMaterialTests(state).isFetching,
  skip: selectMaterialTests(state).skip,
  limit: selectMaterialTests(state).limit,
  showLoadMore: selectMaterialTests(state).showLoadMore,
  error: selectErrorByKey(state, 'materialTests'),
  customerId: selectSelectedCustomerFilter(state)?.customerId
})

const mapDispatchToProps = (dispatch) => ({
  setDefaultDateFilter: (props) => dispatch(setDefaultDateFilter(props)),
  fetchMaterialTests: (analyticsId, userId) => dispatch(fetchMaterialTests(analyticsId, userId)),
  setSortKey: (sortKey) => dispatch(setSortKey(sortKey)),
  loadMore: () => dispatch(loadMore()),
  downloadDocument: (documentId: string, fileName: string) =>
    dispatch(downloadDocument(documentId, fileName)),
  errorsClear: () => dispatch(errorsClear()),
  setLookupFilter: (props: LookupFilterType) => dispatch(setLookupFilter(props)),
  switchToLookup: () => dispatch(switchView({view: MaterialTestView.LOOKUP, clearPrevView: true})),
  sheetRequest: (args) => dispatch(sheetRequest(args))
})
export default connect(mapStateToProps, mapDispatchToProps)(Rmc)
