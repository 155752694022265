import {dateFormatter, GridRowsProp, isoDateFormatter} from '@hconnect/uikit'
import {Box, Card, CardContent, Grid} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ReactComponent as RightArrow} from '../../../Assets/right-arrow.svg'
import {CustomerFeedbackBanner} from '../../../Molecules/CustomerFeedbackProgramme'
import {CustomSkeleton} from '../../../Molecules/CustomSkeleton'
import {FieldValue} from '../../Order/components'

import {SubscriptionStatus} from './Components/SubscriptionStatus'
import {useCementStyles} from './styles'
import {MaterialCertificate} from './types'
import {TrackingPageNames} from '../../../common/constants'

interface TestCertificateCardProps {
  certificateDetails: MaterialCertificate
  onCardClick: (event, row: MaterialCertificate) => void
}

export const TestCertificateCard: React.FC<TestCertificateCardProps> = ({
  certificateDetails,
  onCardClick
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const classesCement = useCementStyles()

  return (
    <Card
      onClick={(event) => onCardClick && onCardClick(event, certificateDetails)}
      sx={{
        marginBottom: 2
      }}
      variant="outlined"
      data-test-id={`certificate-card-${certificateDetails.plantIdUnique}`}
    >
      <CardContent sx={{paddingTop: 3, cursor: 'pointer'}}>
        <Grid container>
          <Grid item xs={6}>
            <FieldValue
              dataTestId={t(`certificate-date-${certificateDetails.plantIdUnique}`)}
              label={t('certificate.columns.certificationDate')}
              value={
                certificateDetails.materialCertifications.length
                  ? dateFormatter(
                      isoDateFormatter(
                        certificateDetails.materialCertifications[0].certificationDate
                      ),
                      language
                    )
                  : '-'
              }
              gutter
            />
          </Grid>
          <Grid item xs={6}>
            <FieldValue
              label={t('certificate.columns.product')}
              value={certificateDetails.materialDescription}
              dataTestId={t(`certificate-material-desc-${certificateDetails.plantIdUnique}`)}
              gutter
            />
          </Grid>
          <Grid item xs={6}>
            <FieldValue
              label={t('certificate.columns.plant')}
              value={certificateDetails.plantName}
              dataTestId={`certificate-plant-${certificateDetails.plantIdUnique}`}
              gutter
            />
          </Grid>
          <Grid item xs={6}>
            <FieldValue
              label={t('certificate.columns.subscriptionStatus')}
              value={<SubscriptionStatus isSubscribed={certificateDetails.isSubscribed} t={t} />}
              dataTestId={`certificate-subscriptionStatus-${certificateDetails.plantIdUnique}`}
              gutter
            />
          </Grid>
          <Grid item xs={12} display="flex">
            <div className={classesCement.downloadRowItem}>
              {t('certificate.columns.certificationLetterButton')}
              <RightArrow />
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

interface Type {
  loading?: boolean
  rows: GridRowsProp<MaterialCertificate>
  keyField: string
  onCardClick: (event, row: MaterialCertificate) => void
  setShowCustomerPanel: (show: boolean) => void
}

export const TestCertificatesCardList: React.FC<Type> = ({
  rows,
  loading,
  keyField,
  onCardClick,
  setShowCustomerPanel
}) => {
  if (loading) {
    return <CustomSkeleton />
  }

  return (
    <Box height="100%" width="100%" data-test-id="certificates-on-smallscreen">
      {rows.length &&
        rows.map((row, index) => (
          <>
            <TestCertificateCard
              key={row[keyField]}
              certificateDetails={row}
              onCardClick={onCardClick}
            />
            {index === 0 && (
              <>
                <CustomerFeedbackBanner
                  setKnowMore={(val: boolean) => setShowCustomerPanel(val)}
                  entryPoint={TrackingPageNames.TEST_REPORTS}
                />
                <Box mt={2} />
              </>
            )}
          </>
        ))}
    </Box>
  )
}
