import {Typography, dateFormatter, timeFormatter} from '@hconnect/uikit'
import {Box, TableCell, TableRow, makeStyles} from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {OrderRequestStatus} from '../../declarations/OrderIntake.enums'
import {
  DeliveryTime,
  HaulerInfo,
  QuantityType
} from '../../declarations/types'
import {OrderStatus} from '../OrderStatus'
import {HaulierInfo} from '../VisualComponents'

const useStyles = makeStyles(() => {
  return {
    errorState: {
      background: 'rgba(218, 9, 1, 0.04)'
    },
    orderRequestMobile: {
      marginTop: 10,
      marginBottom: 10,
      display: 'relative'
    },
    orderStatusMobile: {
      position: 'absolute',
      right: 30
    }
  }
})

interface CollectOrderSummaryItemProps {
  isMobile: boolean
  showLoad: boolean
  showTime: boolean
  orderRequestStatus: OrderRequestStatus
  quantity: number
  quantityType: QuantityType
  quantityUom: string
  collectionDate: string
  collectionTime: DeliveryTime
  customerReference?: string
  driverInstructions?: string
  haulierInformation?: HaulerInfo
}

export const CollectOrderSummaryItem: React.FC<CollectOrderSummaryItemProps> = ({
  isMobile,
  showLoad,
  showTime,
  orderRequestStatus,
  quantity,
  quantityUom,
  collectionDate,
  collectionTime,
  customerReference,
  driverInstructions,
  haulierInformation
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const c = useStyles()
  const isTimesEqual = collectionTime.earliest === collectionTime.latest

  const getOrderStatusClassName = () => {
    switch (orderRequestStatus) {
      case OrderRequestStatus.FAILED:
      case OrderRequestStatus.VALIDATION_FAILED:
        return c.errorState
      default:
        return ''
    }
  }

  return (
    <>
      {isMobile ? (
        <Box display={'flex'} flexDirection={'column'} className={c.orderRequestMobile}>
          <Box className={c.orderStatusMobile}>
            <OrderStatus status={orderRequestStatus} />
          </Box>
          <Box display={'flex'} flexDirection={'column'} marginBottom={2}>
            <Typography variant="caption">{t('orderIntake.materialOrder.load')}</Typography>
            <Typography variant="body1">{`${quantity} ${quantityUom}`}</Typography>
          </Box>
          <Box display={'flex'} flexDirection={'column'} marginBottom={2}>
            <Typography variant="caption">{t('orderIntake.materialOrder.date')}</Typography>
            <Typography variant="body1">{dateFormatter(collectionDate, language)}</Typography>
            {showTime && (
              <Typography variant="body1">
                {isTimesEqual
                  ? timeFormatter(moment(collectionTime.earliest, 'HH:mm:ss'), language)
                  : `${timeFormatter(moment(collectionTime.earliest, 'HH:mm:ss'), language)} -
                  ${timeFormatter(moment(collectionTime.latest, 'HH:mm:ss'), language)}`}
              </Typography>
            )}
          </Box>
          {(driverInstructions || haulierInformation) && (
            <Box display={'flex'} flexDirection={'column'} marginBottom={2}>
              <Typography variant="caption">
                {t('orderIntake.confirmationScreen.additionalInfo')}
              </Typography>
              <Typography variant="body1">{driverInstructions}</Typography>
              <HaulierInfo haulierInformation={haulierInformation} showTitle={true} />
            </Box>
          )}
          {customerReference && (
            <Box display={'flex'} flexDirection={'column'} marginBottom={2}>
              <Typography variant="caption">{t('orderIntake.customerReference')}</Typography>
              <Typography variant="body1">{customerReference}</Typography>
            </Box>
          )}
        </Box>
      ) : (
        <TableRow data-test-id="collect-order-summary-item" className={getOrderStatusClassName()}>
          <TableCell>
            <OrderStatus status={orderRequestStatus} />
          </TableCell>

          {showLoad && (
            <TableCell>
              <Typography variant="body2">{`${quantity} ${quantityUom}`}</Typography>
            </TableCell>
          )}

          <TableCell>
            <Typography variant="body2">{dateFormatter(collectionDate, language)}</Typography>
          </TableCell>

          <TableCell>
            {showTime && <Typography variant="body2">
              {isTimesEqual
                ? timeFormatter(moment(collectionTime.earliest, 'HH:mm:ss'), language)
                : `${timeFormatter(moment(collectionTime.earliest, 'HH:mm:ss'), language)} -
                  ${timeFormatter(moment(collectionTime.latest, 'HH:mm:ss'), language)}`}
            </Typography>}
          </TableCell>

          <TableCell>
            <Typography variant="body2">{driverInstructions}</Typography>
            <HaulierInfo haulierInformation={haulierInformation} showTitle={true} />
          </TableCell>

          <TableCell>
            <Typography variant="body2">{customerReference}</Typography>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
