import {Branding} from '@hconnect/common/branding/Branding.types'
import {getContactHref} from '@hconnect/common/branding/Branding.utils'
import {Link} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import isEmpty from 'lodash/isEmpty'
import React, {ReactElement, ReactNode, useContext, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {setBranding} from './Branding.actions'
import {BrandingState} from './Branding.reducer'
import {selectBrandingConfig} from './Branding.selector'

interface BrandingContext extends Branding {
  NationalLogo: () => ReactElement | null
  LocalLogo: () => ReactElement | null
  contactEmailHref: string
}

const Context = React.createContext<BrandingContext>({
  NationalLogo: () => null,
  LocalLogo: () => null,
  contactEmailHref: ''
})
Context.displayName = 'Branding'

export const useBranding = () => useContext(Context)

const useStyles = makeStyles({
  overlay: {
    position: 'fixed',
    zIndex: 99999,
    width: '100vw',
    height: '100vh',
    background: 'rgba(255, 255, 255, 1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  nationalLogo: {
    maxWidth: 156
  },
  logo: {
    margin: '8px 0'
  }
})

/**
 * BrandingProvider will load branding-configuration based on the current hostname.
 * You can override the hostname detection by setting up an localStorage key/value with:
 *      localStorage.setItem('branding_override', 'hub.heidelbergcement.com')
 *
 */
export const BrandingProvider = ({children}: {children?: ReactNode}) => {
  const classes = useStyles()

  const branding: Branding = useSelector((state: BrandingState) => selectBrandingConfig(state))
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setBranding())
  }, [dispatch])

  const {
    nationalLogoUrl,
    nationalBrandName,
    nationalLogoTitle,
    localLogoUrl,
    localBrandName,
    localLogoTitle,
    contactEmail,
    contactEmailSubject,
    contactFormHref,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    termsAndConditionsOfSale
  } = branding
  const contactEmailHref = getContactHref({contactEmail, contactEmailSubject, contactFormHref})

  // update PageTitle
  const {t} = useTranslation()
  useEffect(() => {
    document.title = t('hub.title', {nationalBrandName, localBrandName})
  }, [t, nationalBrandName, localBrandName])

  return (
    <Context.Provider
      value={{
        ...branding,
        NationalLogo: () =>
          nationalLogoUrl ? (
            <img
              className={classes.nationalLogo}
              src={nationalLogoUrl}
              alt={nationalBrandName}
              title={nationalLogoTitle}
            />
          ) : null,
        LocalLogo: () =>
          localLogoUrl ? (
            <img
              className={classes.logo}
              src={localLogoUrl}
              alt={localBrandName}
              title={localLogoTitle}
            />
          ) : null,
        contactEmailHref
      }}
    >
      {!isEmpty(branding) ? (
        children
      ) : (
        <div className={classes.overlay}>
          <h1>{t('branding.configurationfailure.title')}</h1>
          <p>{t('branding.configurationfailure.description')}</p>
          <div>
            {t('branding.configurationfailure.pleaseretry')}
            <Link onClick={() => window.location.reload()}>
              {t('branding.configurationfailure.retrylink')}
            </Link>
          </div>
        </div>
      )}
    </Context.Provider>
  )
}
