import {Customer} from '../Customers/Customers.types'

import {Payer} from './Payers.types'

export const PAYERS_FETCH_REQUEST_START = 'PAYERS_FETCH_REQUEST_START'
export const PAYERS_FETCH_REQUEST_FINISHED = 'PAYERS_FETCH_REQUEST_FINISHED'
export const PAYERS_FETCH_SUCCESS = 'PAYERS_FETCH_SUCCESS'
export const PAYERS_FETCH_FAILURE = 'PAYERS_FETCH_FAILURE'

export const SELECT_PAYER_FILTER = 'SELECT_PAYER_FILTER'
export const SET_PAYER_FILTER = 'SET_PAYER_FILTER'
export const REMOVE_PAYER_FILTER = 'REMOVE_PAYER_FILTER'
export const PAYER_FILTER = 'payer'

interface PayersFetchRequestStart {
  type: typeof PAYERS_FETCH_REQUEST_START
}
interface PayersFetchRequestFinished {
  type: typeof PAYERS_FETCH_REQUEST_FINISHED
  selectedCustomer: Customer
}

interface PayersFetchSuccess {
  type: typeof PAYERS_FETCH_SUCCESS
  customerId: string
  payers: Payer[]
}

interface PayersFetchFailure {
  type: typeof PAYERS_FETCH_FAILURE
  customerId: string
  error: any
}

export interface SelectPayerFilter {
  type: typeof SELECT_PAYER_FILTER
  payerId: string
}

export interface SetPayerFilter {
  type: typeof SET_PAYER_FILTER
  payer: Payer | null
}

export interface RemovePayerFilter {
  type: typeof REMOVE_PAYER_FILTER
}

export type PayersActionTypes =
  | PayersFetchRequestStart
  | PayersFetchRequestFinished
  | PayersFetchSuccess
  | PayersFetchFailure
  | SelectPayerFilter
  | SetPayerFilter
  | RemovePayerFilter

export interface PayersStateType {
  isFetching: boolean
  entities: {
    [key: string]: Payer[]
  }
  selectedPayer: Payer | null
  error: any
}
