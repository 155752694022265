import React, {createContext, useContext, useReducer} from 'react'

import {featureReducer, initialState} from './Features.reducer'
import {FeaturesDispatch, FeaturesState, FeaturesProviderProps} from './Features.types'

const FeaturesStateContext = createContext<FeaturesState | undefined>(undefined)
const FeaturesDispatchContext = createContext<FeaturesDispatch | undefined>(undefined)

export const FeaturesProvider = ({children}: FeaturesProviderProps) => {
  const [state, dispatch] = useReducer(featureReducer, initialState)
  return (
    <FeaturesStateContext.Provider value={state}>
      <FeaturesDispatchContext.Provider value={dispatch}>
        {children}
      </FeaturesDispatchContext.Provider>
    </FeaturesStateContext.Provider>
  )
}

export const useFeaturesState = () => {
  const context = useContext(FeaturesStateContext)
  if (context === undefined) {
    throw new Error('useFeaturesState should be used within provider')
  }
  return context
}

export const useFeaturesDispatch = () => {
  const context = useContext(FeaturesDispatchContext)
  if (context === undefined) {
    throw new Error('useFeaturesState should be used within provider')
  }
  return context
}
