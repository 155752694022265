import {isEmpty} from 'lodash'

import {trackEvent} from '../../../common/analytics'
import {selectSelectedCustomerFilter} from '../../../Organisms/Customers'
import {
  clearDefaultFiltersAbstract,
  clearFiltersAbstract,
  removeDefaultFilterAbstract,
  removeFilterAbstract,
  setDefaultFilterAbstract,
  setFilterAbstract
} from '../../../Organisms/Filters'
import {
  MATERIALTESTS,
  fetchMaterialTests as fetchMaterialTestsAbstract,
  fetchMaterialTestsFailure,
  fetchMaterialTestsRequest
} from '../../../Organisms/Materialtest'
import {
  fetchDeliveryByDeliveryNumber,
  selectDeliveriesByDeliveryNumber
} from '../../../Organisms/Orders'
import {AppState} from '../../../Root.store'
import {Delivery} from '../../Finance/Invoices/Delivery.types'

import {
  MATERIALTESTS_CLEAR_VIEW,
  MATERIALTESTS_SWITCH_VIEW,
  MaterialTestPageActionTypes
} from './Action.types'
import {LookupEntityParam, LookupSelectItem} from './MaterialTest.enum'
import {
  selectDateFilter,
  selectLookupFilter,
  selectProjectFilter,
  selectSiteFilter
} from './MaterialTest.filters'

export const setFilter = (filterName, filterValue) =>
  setFilterAbstract(MATERIALTESTS, filterName, filterValue)
export const setDefaultFilter = (filterName, filterValue) =>
  setDefaultFilterAbstract(MATERIALTESTS, filterName, filterValue)
export const removeFilter = (filterName) => removeFilterAbstract(MATERIALTESTS, filterName)
export const removeDefaultFilter = (filterName) =>
  removeDefaultFilterAbstract(MATERIALTESTS, filterName)
export const clearFilters = () => clearFiltersAbstract(MATERIALTESTS)
export const clearDefaultFilters = () => clearDefaultFiltersAbstract(MATERIALTESTS)

export const switchView = ({view, clearPrevView}): MaterialTestPageActionTypes => ({
  type: MATERIALTESTS_SWITCH_VIEW,
  view,
  clearPrevView
})

export const clearCurrentView = (): MaterialTestPageActionTypes => ({
  type: MATERIALTESTS_CLEAR_VIEW
})
// FETCH CERTIFICATES
export const fetchMaterialTests =
  (analyticsId?: string, userId?: string) => async (dispatch, getState) => {
    const state: AppState = getState()
    const {customerId} = selectSelectedCustomerFilter(state) || {}
    let params = {}

    const lookup = selectLookupFilter(state)
    const {startDate, endDate} = selectDateFilter(state) || {}
    const {siteId} = selectSiteFilter(state) || {}
    const {projectId} = selectProjectFilter(state) || {}
    if (lookup) {
      const {entity, value} = lookup
      if (entity === LookupSelectItem.TICKET && customerId) {
        let deliveries: Delivery[] = []
        dispatch(fetchMaterialTestsRequest({deliveryNumber: value, customerId}))
        await dispatch(
          fetchDeliveryByDeliveryNumber(value, customerId, false, () =>
            trackEvent('hubLookupFailed', {
              product: 'hub',
              lookupCategory: entity,
              lookupValue: value,
              lookupSource: 'rmctests',
              customerId,
              userId,
              analyticsId
            })
          )
        )
        const latestState: AppState = getState()
        deliveries = selectDeliveriesByDeliveryNumber(latestState, value, customerId)
        if (deliveries && deliveries.length) {
          dispatch(clearCurrentView())
          deliveries.forEach((delivery: Delivery) => {
            const {orderNumber} = delivery
            params = {
              ...params,
              sortedBy: 'shippingDate asc,reportNumber asc,sampleTime asc',
              [LookupEntityParam[LookupSelectItem.TICKET]]: orderNumber,
              skip: 0
            }
            dispatch(fetchMaterialTestsAbstract(params))
          })
          return
        }
        trackEvent('hubLookupFailed', {
          product: 'hub',
          lookupCategory: entity,
          lookupValue: value,
          lookupSource: 'rmctests',
          customerId,
          userId,
          analyticsId
        })
        dispatch(fetchMaterialTestsFailure('entity not found'))
        return
      } else {
        if (customerId) {
          params = {
            ...params,
            sortedBy: 'shippingDate asc,reportNumber asc,sampleTime asc',
            [LookupEntityParam[LookupSelectItem.ORDER]]: value,
            customerId
          }
        }
      }
    } else {
      params = {
        sortedBy: 'shippingDate asc,reportNumber asc,sampleTime asc',
        startDate,
        endDate,
        projectId,
        siteId,
        customerId
      }
    }

    !isEmpty(params) && dispatch(fetchMaterialTestsAbstract(params, lookup, analyticsId))
  }
