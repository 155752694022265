import {BrandingCountries, BusinessLineTypes} from '../../common/types'

export const FEATURES_REQUEST = 'FEATURES_REQUEST'
export const FEATURES_FAILURE = 'FEATURES_FAILURE'
export const FEATURES_SUCCESS = 'FEATURES_SUCCESS'

export interface Feature {
  name: string
  enabled?: boolean
  description: string
  tags: string[]
  constraints: FeatureConstraint[]
}
export type FeatureConstraintType = 'user' | 'role' | 'data' | 'public'

export interface FeatureConstraint {
  type?: FeatureConstraintType
  dataScope: DataScope | string
  enabled: boolean
}

export type DataScopeConstraintsType =
  | 'countryId'
  | 'orgUnitId'
  | 'businessLine'
  | 'country'
  | 'environments'
  | 'connectorId'

export interface DataScope {
  countryId?: BrandingCountries | BrandingCountries[]
  orgUnitId?: string
  roleType?: string
  businessLine?: BusinessLineTypes
}

export interface FeatureResolutionType {
  calculated: boolean
  enabled: string[]
  disabled: string[]
}

export interface NormalizedFeatures {
  [key: string]: Feature
}

export type FeaturesActions =
  | {type: typeof FEATURES_REQUEST}
  | {type: typeof FEATURES_FAILURE; payload: any}
  | {type: typeof FEATURES_SUCCESS; payload: Feature[]}

export type FeaturesDispatch = (action: FeaturesActions) => void

export interface FeaturesState {
  isFetching: boolean
  entities: Feature[]
  normalized: NormalizedFeatures
  error: any
  getFeaturesByTag: (tag: string, features: Feature[]) => Feature[]
  getResolutionByNameAndConstraint: (
    name: string,
    normalized: NormalizedFeatures,
    dataScopeConstraint: DataScopeConstraintsType
  ) => FeatureResolutionType
  getFeatureByName: (name: string, normalized: NormalizedFeatures) => boolean
  getFeature: (name: string) => boolean
}

export type FeaturesProviderProps = {children: React.ReactNode}
