import {AxiosRequestConfig, AxiosResponse} from 'axios'

import {AppState} from '../../Root.store'
import {Customer} from '../Customers/Customers.types'
// import { setPayerFilter } from '../../Pages/Finance/Statements'
import {createSelectFilter, removeFilterAbstract, setFilterAbstract} from '../Filters'
import {INVOICES} from '../Invoices'
import {ORDERS} from '../Orders'
const STATEMENTS = 'STATEMENTS'

import {
  PAYERS_FETCH_FAILURE,
  PAYERS_FETCH_REQUEST_START,
  PAYERS_FETCH_REQUEST_FINISHED,
  PAYERS_FETCH_SUCCESS,
  PayersActionTypes,
  PAYER_FILTER,
  SELECT_PAYER_FILTER,
  SET_PAYER_FILTER,
  REMOVE_PAYER_FILTER
} from './Action.types'
import {Payer} from './Payers.types'

export const selectPayerFilterAbstract = createSelectFilter(PAYER_FILTER)

export const selectPayerFilter = (payerId: string): PayersActionTypes => ({
  type: SELECT_PAYER_FILTER,
  payerId
})
export const setPayerFilter = (payer: Payer | null): PayersActionTypes => ({
  type: SET_PAYER_FILTER,
  payer
})
export const removePayerFilter = (): PayersActionTypes => ({
  type: REMOVE_PAYER_FILTER
})

// based on https://stackoverflow.com/questions/40900354/updating-state-managed-by-another-reducer
const setOrdersPayerFilter = (payer: Payer | null) =>
  setFilterAbstract(ORDERS, PAYER_FILTER, {...payer})
const setInvoicesPayerFilter = (payer: Payer | null) =>
  setFilterAbstract(INVOICES, PAYER_FILTER, {...payer})
const setStatementsPayerFilter = (payer: Payer | null) =>
  setFilterAbstract(STATEMENTS, PAYER_FILTER, {...payer})

export const setGlobalPayerFilter = (payer: Payer | null) => (dispatch) => {
  dispatch(setOrdersPayerFilter(payer))
  dispatch(setInvoicesPayerFilter(payer))
  dispatch(setStatementsPayerFilter(payer))
  return dispatch(setPayerFilter(payer))
}

const removeOrdersPayerFilter = () => removeFilterAbstract(ORDERS, PAYER_FILTER)
const removeInvoicesPayerFilter = () => removeFilterAbstract(INVOICES, PAYER_FILTER)
const removeStatementsPayerFilter = () => removeFilterAbstract(STATEMENTS, PAYER_FILTER)
export const removeGlobalPayerFilter = () => (dispatch) => {
  dispatch(removeOrdersPayerFilter())
  dispatch(removeInvoicesPayerFilter())
  dispatch(removeStatementsPayerFilter())
  return dispatch(removePayerFilter())
}

export const fetchPayersRequestStart = (): PayersActionTypes => ({
  type: PAYERS_FETCH_REQUEST_START
})
export const fetchPayersRequestFinished = (selectedCustomer: Customer): PayersActionTypes => ({
  type: PAYERS_FETCH_REQUEST_FINISHED,
  selectedCustomer
})

export const fetchPayersSuccess = (customerId: string, payers: Payer[]): PayersActionTypes => ({
  type: PAYERS_FETCH_SUCCESS,
  payers,
  customerId
})

export const fetchPayersFailure = (customerId: string, error: any): PayersActionTypes => ({
  type: PAYERS_FETCH_FAILURE,
  error,
  customerId
})

export const fetchPayers =
  (customerId: string) =>
  async (dispatch, getState, {api}) => {
    try {
      const requestConfig: AxiosRequestConfig = {
        params: {customerId}
      }
      const response: AxiosResponse<Payer[]> = await api.get('/payers', requestConfig)

      if (response) {
        dispatch(fetchPayersSuccess(customerId, response.data))
      }
    } catch (e) {
      dispatch(fetchPayersFailure(customerId, e))
    }
  }

export const fetchAllPayers =
  () =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (dispatch, getState, {api}) => {
    dispatch(fetchPayersRequestStart())
    const state: AppState = getState()
    const {customers, selectedCustomer} = state.customers
    const customer: Customer = selectedCustomer || customers[0]

    const promises: any[] = []
    for (let i = 0; i < customers.length; i++) {
      const {customerId} = customers[i]
      promises.push(dispatch(fetchPayers(customerId)))
    }
    void Promise.all(promises).then(() => dispatch(fetchPayersRequestFinished(customer)))
  }
