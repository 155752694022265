import {Typography} from '@hconnect/uikit'
import {LinearProgress, TableCell, TableRow} from '@material-ui/core'
import {FC, ReactElement} from 'react'

import {DeliveryTime, HaulerInfo, QuantityType} from '../../OrderIntake/declarations/types'
import {DeliveryOrderSummaryItem} from './Deliver/DeliveryOrderSummaryItem'
import {OrderRequestStatus, ShippingType} from '../declarations/OrderIntake.enums'
import {CollectOrderSummaryItem} from './Collect/CollectOrderSummaryItem'

interface Props {
  shippingType: ShippingType
  isLoading: boolean
  isMobile: boolean
  showLoad: boolean
  showTime: boolean
  loadingLabel: string
  hasError: boolean
  date: string
  time: DeliveryTime
  quantity: number
  quantityType: QuantityType
  quantityUom: string
  customerReference?: string
  driverInstructions?: string
  haulierInformation?: HaulerInfo
  errorMessage?: ReactElement
}

const ProcessingOrderCard: FC<Props> = ({
  isLoading,
  shippingType,
  isMobile,
  showLoad,
  showTime,
  loadingLabel,
  hasError,
  date,
  time,
  quantity,
  quantityType,
  quantityUom,
  customerReference,
  driverInstructions,
  haulierInformation
}) => {
  return (
    <>
      {isLoading ? (
        <TableRow>
          <TableCell colSpan={6}>
            <Typography>{loadingLabel}</Typography>
            <LinearProgress data-test-id="processing-order-loader" />
          </TableCell>
        </TableRow>
      ) : null}

      {!isLoading && hasError ? (
        <>
          {shippingType === ShippingType.DELIVER ? (
            <DeliveryOrderSummaryItem
              isMobile={isMobile}
              showLoad={showLoad}
              orderRequestStatus={OrderRequestStatus.FAILED}
              quantity={quantity}
              quantityType={quantityType}
              quantityUom={quantityUom}
              deliveryDate={date}
              deliveryTime={time}
              driverInstructions={driverInstructions}
              customerReference={customerReference}
              haulierInformation={haulierInformation}
            />
          ) : (
            <CollectOrderSummaryItem
              isMobile={isMobile}
              showTime={showTime}
              showLoad={showLoad}
              orderRequestStatus={OrderRequestStatus.FAILED}
              quantity={quantity}
              quantityType={quantityType}
              quantityUom={quantityUom}
              collectionDate={date}
              collectionTime={time}
              driverInstructions={driverInstructions}
              customerReference={customerReference}
              haulierInformation={haulierInformation}
            />
          )}
        </>
      ) : null}
    </>
  )
}

export {ProcessingOrderCard}
