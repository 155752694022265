import {makeStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import {get, includes} from 'lodash'
import React from 'react'
import {Trans} from 'react-i18next'

import {useColorStyles, useFontStyles, useLayoutStyles} from '../App.styles'
import {BusinessLineType} from '../common/types'
import {QuantityUomToSymbolComponent} from '../Pages/Order/Order.components'
import {Order, OrderStatus, ShippingType} from '../Pages/Order/Order.types'
import {amountToString} from '../util/numbers'

const useStyles = makeStyles((theme) => ({
  container: {
    color: 'black',
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    paddingLeft: theme.spacing(1.25),
    paddingRight: theme.spacing(2.5),
    borderTop: '1px solid rgb(245, 245, 245)'
  },
  progressContainer: {
    height: '8px'
  },
  poured: {
    backgroundColor: theme.palette.success.main // '#31ca72'
  },
  onSite: {
    backgroundColor: theme.palette.success.dark // '#128845'
  },
  inTransit: {
    backgroundColor: theme.palette.secondary.dark // '#003145'
  },
  loading: {
    backgroundColor: theme.palette.secondary.main
  },
  remaining: {
    backgroundColor: theme.palette.secondary.light // '#b2c3c9'
  }
}))

interface DeliveryProgressBarType {
  order: Order
  noContainer?: boolean
}

enum BucketId {
  poured = 'poured',
  onSite = 'onSite',
  inTransit = 'inTransit',
  remaining = 'remaining',
  notUsed = 'notUsed'
}

export const DeliveryProgressBar: React.FC<DeliveryProgressBarType> = ({
  order,
  noContainer = false
}) => {
  const classes = useStyles()
  const lc = useLayoutStyles()
  const cc = useColorStyles()
  const fc = useFontStyles()

  // LOGIC DESCRIBED IN https://jira.grouphc.net/browse/HB-1078
  const lineItems = get(order, 'lineItems', [])
  const orderStatus = get(order, 'orderStatus', OrderStatus.Delivered)
  const orderedAmount: any = get(lineItems, [0, 'quantity'], 0)
  const orderedAmountUom = get(lineItems, [0, 'quantityUom'], '-')
  const shippingType = get(order, 'shippingType', 'deliver')

  const isCompletedOrder = includes([OrderStatus.Delivered, OrderStatus.Shipped], orderStatus)

  const shippedQuantity: number = get(order, ['deliveryProgress', 'shippedQuantity'], 0)

  const arrivedQuantity: number = get(order, ['deliveryProgress', 'arrivedQuantity'], 0)

  const deliveredQuantity: number = get(order, ['deliveryProgress', 'deliveredQuantity'], 0)

  const businessLine = get(order, 'businessLine', 'unknown business line')

  let completedLabelType = 'delivered'
  if (businessLine === BusinessLineType.RMC) {
    completedLabelType = 'poured'
  } else if (shippingType === ShippingType.Collect) {
    completedLabelType = 'collected'
  }

  const bucketOrdering = [BucketId.poured, BucketId.onSite, BucketId.inTransit, BucketId.remaining]

  const buckets = {
    [BucketId.poured]: {
      qty: deliveredQuantity,
      label: (
        <Trans
          i18nKey={`order.progressBar.${completedLabelType}`}
          values={{
            amount: amountToString(deliveredQuantity),
            uom: '<0>orderedAmountUom</0>'
          }}
          // eslint-disable-next-line react/jsx-key
          components={[<QuantityUomToSymbolComponent uom={orderedAmountUom} />]}
        />
      ),
      colorClass: classes.poured
    },
    [BucketId.onSite]: {
      qty: arrivedQuantity - deliveredQuantity,
      label: (
        <Trans
          i18nKey="order.progressBar.onSite"
          values={{
            amount: amountToString(arrivedQuantity - deliveredQuantity),
            uom: '<0>orderedAmountUom</0>'
          }}
          // eslint-disable-next-line react/jsx-key
          components={[<QuantityUomToSymbolComponent uom={orderedAmountUom} />]}
        />
      ),
      colorClass: classes.onSite
    },
    [BucketId.inTransit]: {
      qty: shippedQuantity - arrivedQuantity,
      label: (
        <Trans
          i18nKey="order.progressBar.inTransit"
          values={{
            amount: amountToString(shippedQuantity - arrivedQuantity),
            uom: '<0>orderedAmountUom</0>'
          }}
          // eslint-disable-next-line react/jsx-key
          components={[<QuantityUomToSymbolComponent uom={orderedAmountUom} />]}
        />
      ),
      colorClass: classes.inTransit
    },
    [BucketId.remaining]: {
      qty: orderedAmount - shippedQuantity,
      label: null,
      colorClass: classes.remaining
    },
    [BucketId.notUsed]: {
      qty: 0,
      label: 'not used',
      colorClass: classes.remaining
    }
  }

  let topRightLabel: string | JSX.Element = ''

  if (orderedAmount < shippedQuantity) {
    topRightLabel = (
      <Trans
        i18nKey="order.progressBar.remainingMore"
        values={{
          amountMore: amountToString(shippedQuantity - orderedAmount),
          amount: amountToString(orderedAmount),
          uom: '<0>orderedAmountUom</0>'
        }}
        // eslint-disable-next-line react/jsx-key
        components={[<QuantityUomToSymbolComponent uom={orderedAmountUom} />]}
      />
    )
  } else if (orderedAmount > shippedQuantity && isCompletedOrder) {
    topRightLabel = (
      <Trans
        i18nKey="order.progressBar.remainingShort"
        values={{
          amountShort: amountToString(orderedAmount - shippedQuantity),
          amount: amountToString(orderedAmount),
          uom: '<0>orderedAmountUom</0>'
        }}
        // eslint-disable-next-line react/jsx-key
        components={[<QuantityUomToSymbolComponent uom={orderedAmountUom} />]}
      />
    )
  } else if (orderedAmount > shippedQuantity && !isCompletedOrder) {
    topRightLabel = (
      <Trans
        i18nKey="order.progressBar.remainingRemaining"
        values={{
          amountRemaining: amountToString(orderedAmount - shippedQuantity),
          amount: amountToString(orderedAmount),
          uom: '<0>orderedAmountUom</0>'
        }}
        // eslint-disable-next-line react/jsx-key
        components={[<QuantityUomToSymbolComponent uom={orderedAmountUom} />]}
      />
    )
  } else {
    topRightLabel = (
      <Trans
        i18nKey="order.progressBar.remainingMatch"
        values={{
          amount: amountToString(orderedAmount),
          uom: '<0>orderedAmountUom</0>'
        }}
        // eslint-disable-next-line react/jsx-key
        components={[<QuantityUomToSymbolComponent uom={orderedAmountUom} />]}
      />
    )
  }

  return (
    <div
      className={classNames({[classes.container]: !noContainer}, lc.flx, lc.flxCol)}
      data-test-id={`progress-bar-${order.orderId}`}
    >
      <div className={classNames(lc.flx, lc.jcfe)}>
        <div className={classNames(fc.label, cc.text)}>{topRightLabel}</div>
      </div>
      <div className={classNames(lc.flx, lc.flxRow)}>
        {bucketOrdering.map((bucketId) => {
          const item = buckets[bucketId]
          const minWidth = bucketId !== BucketId.remaining ? '190px' : '10px'
          if (item.qty > 0) {
            return (
              <div
                key={bucketId}
                style={{flex: item.qty, marginLeft: '4px', minWidth}}
                className={classNames(lc.flxCol)}
              >
                <div className={classNames(lc.flx1, item.colorClass, classes.progressContainer)} />
                <div
                  className={classNames(fc.label, cc.text)}
                  style={bucketId === BucketId.poured ? {float: 'right'} : {}}
                >
                  {bucketId !== BucketId.remaining && item.label}
                </div>
              </div>
            )
          }
          return null
        })}
      </div>
    </div>
  )
}
