import {useBreakPoints} from '@hconnect/uikit'
import {makeStyles} from 'tss-react/mui'

import {ScreenSizeLabel} from '../common/constants'

export const useResponsiveGridStyles = makeStyles()((theme) => ({
  gridItem: {
    overflowY: 'auto',
    overscrollBehavior: 'contain',
    '&::-webkit-scrollbar': {
      width: '0.5em'
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555'
    }
  },
  overlay: {
    background: '#fff',
    color: theme.palette.text.primary,
    marginTop: theme.spacing(1),
    zIndex: 1000
  },
  overlayContent: {
    padding: theme.spacing(3),
    marginBottom: 60
  }
}))

export const useResponsiveBreakpoints = () => {
  const screenSize = useBreakPoints()
  const smallScreen = ['sm', 'xs'].includes(screenSize)
  const mediumScreen = ['md'].includes(screenSize)
  const largeScreen = ['lg', 'xl'].includes(screenSize)
  return {screenSize, smallScreen, mediumScreen, largeScreen}
}

export const useScreenSizeLabel = () => {
  const {smallScreen, mediumScreen, largeScreen} = useResponsiveBreakpoints()
  if (smallScreen) return ScreenSizeLabel.SMALL
  if (mediumScreen) return ScreenSizeLabel.MEDIUM
  if (largeScreen) return ScreenSizeLabel.LARGE
  return ScreenSizeLabel.UNDEFINED
}

export const ResponsiveHeight = 'calc(100vh - 220px)'
