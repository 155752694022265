import {HCThemeType} from '@hconnect/uikit'
import {makeStyles, Button, Box, useTheme, useMediaQuery} from '@material-ui/core'
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt'
import WestIcon from '@mui/icons-material/West'
import CloseIcon from '@material-ui/icons/Close'
import DateRange from '@material-ui/icons/DateRange'
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined'
import EventNoteIcon from '@material-ui/icons/EventNote'
import DeleteIcon from '@mui/icons-material/Delete'
import {TabContext} from '@material-ui/lab'
import {Typography} from '@mui/material'
import classnames from 'classnames'
import React, {useEffect, useReducer, useState} from 'react'
import {FormProvider, FormState, useForm, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {Dictionary} from '../../../../common/types'
import {useConfirmation} from '../../../../OrderIntake/components/ConfirmationDialog/ConfirmationService'
import {useFeaturesState} from '../../../../Organisms/Features'
import {useBulkCementOrderIntake} from '../../../../Organisms/OrderIntake/BulkCementOrderIntake.provider'
import {BulkOrderFormValues} from '../../../BulkOrdersFormValues'
import {ShippingType} from '../../../declarations/OrderIntake.enums'
import {
  ActiveMaterialDeliveryItem,
  ActiveMaterialEditorMode,
  DeliveryTime,
  HaulerInfo,
  OrderIntakeMaterialOptionPayload,
  OrderIntakePayload,
  TimeSelectorTrackingEventType
} from '../../../declarations/types'

import {
  ApplyButtonContainer,
  CollapsedTab,
  CollapsedTabs,
  ContentBox,
  MaterialEditorCloseButton,
  TabPanel
} from './components'
import testIds from './dataTestIds'
import {DateTimeTab} from './DateTimeTab'
import {HaulierInfoTab} from './HaulierInfoTab'
import {PoTab} from './PoTab'
import {MaterialEditorTabs, MaterialOrderForm} from './types'
import {getCurrentTab} from './utils'
import {DetailsTab} from './DetailsTab'
import {
  getValidAndInvalidMaterialsForCollect,
  getValidAndInvalidMaterialsForDelivery,
  ResolvedMaterialSettings
} from '../../utils'
import {getDefaultMaterialOptions} from '../../../../Organisms/OrderIntake/utils'

const customerPrefix = ' Customer: '

const useStyles = makeStyles((theme: HCThemeType) => {
  const borderBox = `1px solid ${theme.palette.grey[200]}`
  return {
    tabButton: {
      textTransform: 'none'
    },
    applyButtonContainer: {
      backgroundColor: theme.palette.grey[50],
      borderTop: borderBox,
      flexGrow: 0,
      padding: theme.spacing(2),
      position: 'sticky',
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      bottom: -1 * theme.spacing(1),
      '& svg': {
        marginRight: theme.spacing(1)
      }
    },
    closeButtonContainer: {
      alignSelf: 'end',
      padding: 5
    }
  }
})

const getIndicatorClassName = (
  formState: FormState<MaterialOrderForm>,
  path: keyof MaterialOrderForm
) => {
  if (formState.errors[path]) return 'error'
  if (formState.dirtyFields[path]) return 'dirty'
  return undefined
}
const Indicator: React.FC<{state: FormState<MaterialOrderForm>; path: keyof MaterialOrderForm}> = (
  p
) => <span className={classnames('indicator', getIndicatorClassName(p.state, p.path))}></span>

type Props = {
  key: string
  orderPayload: OrderIntakePayload
  index: number // the index of the form
  canDeleteMaterial: boolean
  isCustomerReferenceSame: boolean
  checkValidityBySlots: boolean
  shippingType: ShippingType
  showPoTab: boolean
  activeItem?: ActiveMaterialDeliveryItem
  editorMode?: ActiveMaterialEditorMode
  closeActiveItem: () => void
  onDateChange: (date: string) => void
  onTimeChange: (date: DeliveryTime) => void
  onQuantityChange: (quantity: number) => void
  onMaterialChange: (selectedMaterialOption: OrderIntakeMaterialOptionPayload) => void
  onHaulerInfoChange?: (haulerInfo?: HaulerInfo) => void
  onCustomerReferenceChange?: (customerReference: string) => void
  onDriverInstructionsChange?: (customerReference: string) => void
  onChange: (data: MaterialOrderForm) => void
  onTimeSelectorTrack?: (events: Dictionary<number>) => void
  onDeleteMaterial: () => void
  onStatusChange: (hasChanges: boolean) => void
}

// eslint-disable-next-line complexity
export const MaterialOrderEditor: React.FC<Props> = ({
  index,
  orderPayload,
  canDeleteMaterial,
  activeItem,
  editorMode,
  checkValidityBySlots,
  closeActiveItem,
  onDateChange,
  onTimeChange,
  onHaulerInfoChange,
  onCustomerReferenceChange,
  onQuantityChange,
  onMaterialChange,
  onDriverInstructionsChange,
  onChange,
  onDeleteMaterial,
  shippingType,
  onTimeSelectorTrack,
  onStatusChange
}) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const {getFeature} = useFeaturesState()
  const confirm = useConfirmation()
  const [activeTab, setActiveTab] = useState(getCurrentTab(activeItem))

  useEffect(() => {
    setActiveTab(getCurrentTab(activeItem))
  }, [activeItem])

  const parentForm = useFormContext<BulkOrderFormValues>()
  const orderPath = `orders.${index}` as const
  const selectedSite = parentForm.watch('selectedSite')

  const order = parentForm.getValues(orderPath)
  const defaultMaterialOption = getDefaultMaterialOptions(selectedSite.materials, orderPayload)
  const selectedMaterial = parentForm.watch(`selectedMaterial.${index}`) ?? defaultMaterialOption

  const [timeSelectorEvents, setTimeSelectorEvents] = useState<Dictionary<number>>()

  const methods = useForm<MaterialOrderForm>({
    mode: 'onChange',
    defaultValues: {
      dateTime: {
        deliveryDate: orderPayload.deliveryDate,
        deliveryTime: orderPayload.deliveryTime
      },
      haulerInfo: {
        driverName: orderPayload.haulerInfo?.driverName,
        driverPhoneNumber: orderPayload.haulerInfo?.driverPhoneNumber,
        trailerLicensePlate: orderPayload.haulerInfo?.trailerLicensePlate,
        truckLicensePlate: orderPayload.haulerInfo?.truckLicensePlate
      },
      details: {
        customerReference: orderPayload.customerReference,
        quantity: orderPayload.capacity.quantity,
        driverInstructions: orderPayload.additionalDriverInfo?.driverInstructions
      }
    }
  })

  const defaultMaterial = selectedSite.materials[orderPayload.materialEnteredNumber][0]
  const [isItemOpen, hideItem] = useReducer(() => false, true)

  useEffect(() => {
    hideItem()

    const driverInstructions = orderPayload.additionalDriverInfo?.driverInstructions
    const readOnlyDriversInstructions = selectedSite?.driverInstructions ?? ''

    const isReadOnlyDriversInstructionsAvailable = readOnlyDriversInstructions
      ? readOnlyDriversInstructions.length > 0
      : false

    let resolvedDriverInstructions = driverInstructions

    if (isReadOnlyDriversInstructionsAvailable) {
      resolvedDriverInstructions = driverInstructions?.replace(
        `${readOnlyDriversInstructions}${customerPrefix}`,
        ''
      )
    }
    methods.setValue('details.driverInstructions', resolvedDriverInstructions ?? '')
  }, [])

  React.useEffect(() => {
    const subscription = methods.watch((value, {type}) => {
      if (type === 'change') {
        onStatusChange && onStatusChange(true)
      }
    })
    return () => subscription.unsubscribe()
  }, [methods.watch])

  const {slotConfiguration} = useBulkCementOrderIntake()

  // eslint-disable-next-line complexity
  const submit = methods.handleSubmit((data) => {
    closeActiveItem()
    // to call more fieldArray methods one after another is problem. we should do it at once.
    // needs rework when more are handled by single submit
    if (methods.formState.dirtyFields.dateTime?.deliveryTime) {
      onTimeChange(data.dateTime.deliveryTime)
    }
    if (methods.formState.dirtyFields.dateTime?.deliveryDate) {
      onDateChange(data.dateTime.deliveryDate)
    }

    if (methods.formState.dirtyFields.haulerInfo) {
      onHaulerInfoChange && onHaulerInfoChange(data.haulerInfo)
    }

    if (methods.formState.dirtyFields.details?.customerReference) {
      onCustomerReferenceChange && onCustomerReferenceChange(data.details.customerReference)
    }

    if (methods.formState.dirtyFields.details?.quantity) {
      onQuantityChange && onQuantityChange(data.details.quantity)
    }

    if (methods.formState.dirtyFields.details?.driverInstructions) {
      const driverInstructions = data.details.driverInstructions
      const readOnlyDriversInstructions = selectedSite?.driverInstructions ?? ''
      const isReadOnlyDriversInstructionsAvailable = readOnlyDriversInstructions
        ? readOnlyDriversInstructions.length > 0
        : false

      let resolvedDriverInstructions = isReadOnlyDriversInstructionsAvailable
        ? driverInstructions
          ? `${readOnlyDriversInstructions}${customerPrefix}${driverInstructions}`
          : readOnlyDriversInstructions
        : driverInstructions

      if (isReadOnlyDriversInstructionsAvailable && !driverInstructions) {
        resolvedDriverInstructions = ''
      }

      onDriverInstructionsChange && onDriverInstructionsChange(resolvedDriverInstructions)
    }

    if (methods.formState.dirtyFields.details?.selectedMaterialOption) {
      data.details.selectedMaterialOption.customerReference = data.details.customerReference
      onMaterialChange && onMaterialChange(data.details.selectedMaterialOption)
    }

    onTimeSelectorTrack && timeSelectorEvents && onTimeSelectorTrack(timeSelectorEvents)

    onChange(data)
  })

  const handleCloseActiveItem = () => {
    if (methods.formState.isDirty) {
      void confirm({
        'data-test-id': testIds.confirmDialog,
        title: t('orderIntake.materialOrder.confirmDialog.title'),
        submitButtonTitle: t('orderIntake.materialOrder.confirmDialog.submitButton'),
        cancelButtonTitle: t('orderIntake.materialOrder.confirmDialog.cancelButton'),
        variant: 'discard'
      }).then(() => {
        closeActiveItem()
      })
    } else {
      closeActiveItem()
    }
  }

  const timeSelectorTrack = (eventType: TimeSelectorTrackingEventType) => {
    const eventsCopy = {...timeSelectorEvents}
    const count = eventsCopy[eventType] ?? 0
    eventsCopy[eventType] = count + 1

    setTimeSelectorEvents(eventsCopy)
  }

  const getDateTimeError = () => {
    return getIndicatorClassName(methods.formState, 'dateTime') === 'error'
  }

  const handleBack = () => {
    switch (activeTab) {
      case MaterialEditorTabs.Date:
        setActiveTab(MaterialEditorTabs.Details)
        break
      case MaterialEditorTabs.Haulier:
        setActiveTab(MaterialEditorTabs.Date)
        break
    }
  }

  const handleNextOrSubmit = () => {
    if (editorMode === 'add') {
      switch (activeTab) {
        case MaterialEditorTabs.Details:
          setActiveTab(MaterialEditorTabs.Date)
          break
        case MaterialEditorTabs.Date:
          if (
            shippingType === ShippingType.COLLECT &&
            getFeature('OrderIntakeHaulierInformation')
          ) {
            setActiveTab(MaterialEditorTabs.Haulier)
          } else {
            submit()
          }
          break
        case MaterialEditorTabs.Haulier:
          submit()
          break
      }
    } else {
      submit()
    }
  }

  const handleDelete = () => {
    onDeleteMaterial()
  }

  const deliveryDate = methods.watch('dateTime.deliveryDate')
  const materialEnteredNumber =
    methods.watch('details.selectedMaterialOption')?.material.materialEnteredNumber ??
    defaultMaterial.material.materialEnteredNumber

  const shouldFlaggedMaterialsBeFiltered = getFeature('OrderIntakeRemoveIrrelevantMaterials')
  const materialOptions = Object.values(selectedSite.materials).flatMap((m) => m)

  const [resolvedMaterialSettings, setResolvedMaterialSettings] =
    useState<ResolvedMaterialSettings>()

  useEffect(() => {
    const materialSettings =
      shippingType === ShippingType.DELIVER
        ? getValidAndInvalidMaterialsForDelivery(
            materialOptions,
            selectedSite.materials,
            materialEnteredNumber,
            deliveryDate,
            checkValidityBySlots,
            slotConfiguration,
            shouldFlaggedMaterialsBeFiltered
          )
        : getValidAndInvalidMaterialsForCollect(
            selectedSite.materials,
            selectedMaterial,
            materialEnteredNumber,
            deliveryDate,
            shouldFlaggedMaterialsBeFiltered
          )

    setResolvedMaterialSettings(materialSettings)

    if (materialSettings.materialOption)
      methods.setValue('details.selectedMaterialOption', materialSettings.materialOption)
  }, [deliveryDate])

  const getApplyButtonLabel = () => {
    if (editorMode === 'add') {
      switch (activeTab) {
        case MaterialEditorTabs.Details:
          return t('orderIntake.materialOrder.addNextDateTime')
        case MaterialEditorTabs.Date:
          return shippingType === ShippingType.COLLECT &&
            getFeature('OrderIntakeHaulierInformation')
            ? t('orderIntake.materialOrder.addHaulierInfo')
            : t('orderIntake.materialOrder.finishSave')
        case MaterialEditorTabs.Haulier:
          return t('orderIntake.materialOrder.finishSave')
      }
    }

    return t('orderIntake.materialOrder.apply')
  }

  if (!order) return null

  const isFormInvalid =
    !methods.formState.isValid ||
    getDateTimeError() ||
    getIndicatorClassName(methods.formState, 'details') === 'error' ||
    getIndicatorClassName(methods.formState, 'haulerInfo') === 'error' ||
    getIndicatorClassName(methods.formState, 'dateTime') === 'error'

  return (
    <form onSubmit={submit}>
      <FormProvider {...methods}>
        <TabContext value={activeTab}>
          <CollapsedTabs
            style={!isMobile ? {borderBottom: 'none'} : {}}
            value={activeTab}
            onChange={(e, value) => setActiveTab(value as MaterialEditorTabs)}
          >
            <CollapsedTab
              value={MaterialEditorTabs.Details}
              selected={activeTab === MaterialEditorTabs.Details}
              data-test-id={testIds.tabs.details}
              label={
                <Typography
                  className={classes.tabButton}
                  variant="body2"
                  color={activeTab === MaterialEditorTabs.Details ? 'primary' : 'text.primary'}
                >
                  <EventNoteIcon />{' '}
                  {t('orderIntake.materialOrder.details')}
                  <Indicator state={methods.formState} path="details" />
                </Typography>
              }
            />

            <CollapsedTab
              value={MaterialEditorTabs.Date}
              selected={activeTab === MaterialEditorTabs.Date}
              data-test-id={testIds.tabs.dateTime}
              label={
                <Typography
                  className={classes.tabButton}
                  variant="body2"
                  color={activeTab === MaterialEditorTabs.Date ? 'primary' : 'text.primary'}
                >
                  <DateRange />{' '}
                  {t('orderIntake.materialOrder.dateTime')}
                  <Indicator state={methods.formState} path="dateTime" />
                </Typography>
              }
            />
            {shippingType === ShippingType.COLLECT &&
              getFeature('OrderIntakeHaulierInformation') && (
                <CollapsedTab
                  value={MaterialEditorTabs.Haulier}
                  selected={activeTab === MaterialEditorTabs.Haulier}
                  data-test-id={testIds.tabs.haulier}
                  label={
                    <Typography
                      className={classes.tabButton}
                      variant="body2"
                      color={activeTab === MaterialEditorTabs.Haulier ? 'primary' : 'text.primary'}
                    >
                      <LocalShippingOutlinedIcon />{' '}
                      {t('orderIntake.materialOrder.haulierInfo')}
                      <Indicator state={methods.formState} path="haulerInfo" />
                    </Typography>
                  }
                />
              )}
          </CollapsedTabs>
          <ContentBox style={{border: 'none'}}>
            <MaterialEditorCloseButton
              data-test-id={testIds.closeBtn}
              aria-label="Close"
              onClick={handleCloseActiveItem}
            >
              <CloseIcon />
            </MaterialEditorCloseButton>

            <TabPanel value={MaterialEditorTabs.Details} data-test-id={testIds.tabPanel.details}>
              <DetailsTab
                selectedSite={selectedSite ?? []}
                validMaterialOptions={resolvedMaterialSettings?.validMaterialOptions ?? []}
                invalidMaterialOptions={resolvedMaterialSettings?.invalidMaterialOptions ?? []}
                isTimeForOrderOver={parentForm.watch('isTimeForOrderOver')}
                index={index}
                slotConfigurations={slotConfiguration}
                orderRequest={order}
                shippingType={shippingType}
                defaultMaterialOption={defaultMaterial}
                onTimeSelectorTrack={timeSelectorTrack}
              />
            </TabPanel>
            <TabPanel value={MaterialEditorTabs.Date} data-test-id={testIds.tabPanel.dateTime}>
              <DateTimeTab
                selectedSite={selectedSite}
                slotConfigurations={slotConfiguration}
                order={order}
                shippingType={shippingType}
                initiallyOpenedItem={isItemOpen ? activeItem : undefined}
                defaultMaterialOption={defaultMaterial}
                onTimeSelectorTrack={timeSelectorTrack}
              />
            </TabPanel>
            <TabPanel value={MaterialEditorTabs.Haulier} data-test-id={testIds.tabPanel.haulier}>
              <HaulierInfoTab />
            </TabPanel>
            <TabPanel value={MaterialEditorTabs.Po} data-test-id={testIds.tabPanel.po}>
              <PoTab />
            </TabPanel>

            <ApplyButtonContainer>
              <Box display="flex" flexDirection="row" gridColumnGap={10}>
                {activeTab !== MaterialEditorTabs.Details && (
                  <Button
                    data-test-id={testIds.backBtn}
                    disabled={!methods.formState.isValid || getDateTimeError()}
                    onClick={handleBack}
                  >
                    <WestIcon />
                    {t('orderIntake.materialOrder.back')}
                  </Button>
                )}

                <Button
                  data-test-id={testIds.submitBtn}
                  color="primary"
                  style={isFormInvalid ? {background: '#B2C3C9'} : {background: '#29AAFF'}}
                  fullWidth
                  disabled={isFormInvalid}
                  onClick={handleNextOrSubmit}
                >
                  <ArrowRightAlt />
                  {getApplyButtonLabel()}
                </Button>
              </Box>

              {canDeleteMaterial && (
                <Button
                  data-test-id={testIds.deleteBtn}
                  fullWidth
                  onClick={handleDelete}
                  style={{color: 'red'}}
                  disabled={!methods.formState.isValid || getDateTimeError()}
                >
                  <DeleteIcon sx={{color: 'red'}} />
                  {editorMode === 'add'
                    ? t('orderIntake.materialOrder.discardChanges')
                    : t('orderIntake.materialOrder.deleteMaterial')}
                </Button>
              )}
            </ApplyButtonContainer>
          </ContentBox>
        </TabContext>
      </FormProvider>
    </form>
  )
}
