import {isEmpty, every} from 'lodash'

import {
  DataScopeConstraintsType,
  Feature,
  FeaturesActions,
  FeatureResolutionType,
  FeaturesState,
  FEATURES_FAILURE,
  FEATURES_REQUEST,
  FEATURES_SUCCESS,
  NormalizedFeatures
} from './Features.types'

export const getFeatureByName = (name: string, normalized: NormalizedFeatures): boolean => {
  const normalizedFeature = normalized[name]
  if (!normalizedFeature) return false
  if (normalizedFeature.enabled) return true
  if (!isEmpty(normalizedFeature.constraints)) {
    return every(normalizedFeature.constraints, 'enabled')
  }
  return false
}

export const getResolutionByNameAndConstraint = (
  name: string,
  normalized: NormalizedFeatures,
  dataScopeConstraint: DataScopeConstraintsType
): FeatureResolutionType => {
  const constraints = normalized[name] ? normalized[name].constraints : undefined
  if (constraints && constraints.length > 0) {
    return constraints.reduce<FeatureResolutionType>(
      (agg, curr) => {
        const values =
          typeof curr.dataScope[dataScopeConstraint] === 'string'
            ? [curr.dataScope[dataScopeConstraint]]
            : curr.dataScope[dataScopeConstraint]
        if (curr.enabled) {
          agg.enabled = [...agg.enabled, ...values]
        } else {
          agg.disabled = [...agg.disabled, ...values]
        }
        return agg
      },
      {calculated: true, enabled: [], disabled: []}
    )
  }

  return {
    calculated: false,
    enabled: [],
    disabled: []
  }
}

export const getFeaturesByTag = (tags: string, entities: Feature[]): Feature[] => {
  return entities.filter((feature) => feature.tags.some((tag) => tags.includes(tag)))
}

export const initialState: FeaturesState = {
  isFetching: false,
  entities: [],
  normalized: {},
  error: null,
  getFeatureByName,
  getResolutionByNameAndConstraint,
  getFeaturesByTag,
  getFeature: (name: string) => getFeatureByName(name, initialState.normalized)
}

export const featureReducer = (
  state: FeaturesState = initialState,
  action: FeaturesActions
): FeaturesState => {
  switch (action.type) {
    case FEATURES_REQUEST:
      return {...state, isFetching: true}
    case FEATURES_FAILURE:
      return {...state, isFetching: false, error: action.payload}
    case FEATURES_SUCCESS: {
      const normalized = action.payload.reduce<NormalizedFeatures>((agg, curr) => {
        agg[curr.name] = {
          ...curr,
          constraints: curr.constraints
            ? [
                ...curr.constraints.map((constraint) => ({
                  ...constraint,
                  dataScope: JSON.parse(constraint.dataScope as string)
                }))
              ]
            : []
        }
        return agg
      }, {})
      return {
        ...state,
        isFetching: false,
        error: null,
        entities: action.payload.map((feature) => ({
          ...feature,
          constraints: feature.constraints
            ? feature.constraints.map((constraint) => ({
                ...constraint,
                dataScope: JSON.parse(constraint.dataScope as string)
              }))
            : []
        })),
        normalized,
        getFeature: (name: string) => getFeatureByName(name, normalized)
      }
    }

    default:
      throw new Error('Unhandled action type')
  }
}
