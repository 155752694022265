import {makeStyles} from '@material-ui/core'

export const useAccountMenuStyles = makeStyles((theme) => ({
  activeMenuButton: {
    backgroundColor: theme.palette.grey[50],
    boxShadow: 'none'
  },
  accountButton: {
    width: '45px',
    height: '45px'
  },
  overlayVisibility: {
    [theme.breakpoints.up('md')]: {
      opacity: 0,
      pointerEvents: 'none'
    }
  },
  tab: {
    textTransform: 'capitalize'
  },
  overlay: {
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  overlayContent: {
    position: 'relative',
    left: 0,
    transitionProperty: 'left',
    transitionDuration: '.5s'
  },
  menu: {
    borderRadius: '8px',
    marginTop: '32px',
    padding: 0,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    display: 'inline-block',
    width: '100%'
  },
  subMenu: {
    justifyContent: 'space-between'
  },
  subMenuList: {
    marginLeft: '5.5%',
    position: 'absolute',
    width: '90%'
  },
  subMenuOpen: {
    left: '-95%'
  },
  item: {
    padding: '18px 24px'
  },
  userIcon: {
    width: '80px',
    height: '80px',
    borderRadius: '40px',
    lineHeight: '80px',
    margin: 'auto',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 500
  },
  name: {
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '22px',
    wordWrap: 'break-word',
    whiteSpace: 'pre-line'
  },
  email: {
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    wordWrap: 'break-word',
    whiteSpace: 'pre-line'
  },
  accountsHeader: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px'
  },
  account: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px'
  },
  accountsElements: {
    margin: '24px'
  },
  flag: {
    width: 25,
    height: 16,
    marginRight: `${theme.spacing(0.5)}px`
  },
  icon: {
    marginRight: '8px',
    fill: '#54708C !important'
  },
  iconRight: {
    marginLeft: '8px'
  },
  logoutIcon: {
    color: '#DA0901'
  },
  carrot: {
    fontSize: 'inherit !important'
  },
  divider: {
    margin: '0 24px'
  },
  orange: {
    color: '#D15C02',
    backgroundColor: 'rgba(209,92,2,0.2) !important'
  },
  purple: {
    color: '#8C3CC9',
    backgroundColor: 'rgba(140,60,201,0.2) !important'
  },
  green: {
    color: '#009D93',
    backgroundColor: 'rgba(0,157,147,0.2) !important'
  },
  blue: {
    color: '#0080D6',
    backgroundColor: 'rgba(0,128,214,0.2) !important'
  }
}))
