import {Typography} from '@hconnect/uikit'
import {Box, Link as LinkComponent} from '@material-ui/core'
import React from 'react'
import {Link} from 'react-router-dom'

interface InternalLinkProps {
  title: string
  endpoint?: string
  props?: React.ReactPropTypes
}

const InternalLink: React.FC<InternalLinkProps> | JSX.Element = ({title, endpoint, ...props}) => {
  if (endpoint) {
    return (
      <LinkComponent
        role="link"
        component={Link}
        variant="body2"
        color="secondary"
        to={endpoint}
        {...props}
      >
        {title}
      </LinkComponent>
    )
  }
  return (
    <Typography variant="body2" color="secondary" {...props}>
      {title}
    </Typography>
  )
}

const Separator: React.FC = () => (
  <Typography variant="body2" color="secondary">
    &nbsp;|&nbsp;
  </Typography>
)

export interface LegalLinkType {
  title: any
  key: string
  endpoint?: string
  href?: string
  isInternal?: boolean
  onClick?: () => void
  target?: string
}
interface LegalLinksProps {
  links: LegalLinkType[]
}

export const LegalLinks: React.FC<LegalLinksProps> = ({links = []}) => {
  const {length} = links
  const keyAttr = (i: number, key: string) => (i < length - 1 ? `${key}-divider` : key)
  return (
    <Box display="flex">
      {links.map(({isInternal, key, title, endpoint, onClick, target = '_blank'}, i) => {
        if (isInternal) {
          return (
            <React.Fragment key={keyAttr(i, key)}>
              <InternalLink
                title={title}
                endpoint={endpoint}
                data-test-id={`footer-copyright-${key}`}
              />
              {i < links.length - 1 && <Separator />}
            </React.Fragment>
          )
        }

        return (
          <React.Fragment key={keyAttr(i, key)}>
            <LinkComponent
              variant="body2"
              color="secondary"
              href={endpoint}
              onClick={onClick}
              target={target}
              rel="noopener noreferrer"
              data-test-id={`footer-copyright-${key}`}
            >
              {title}
            </LinkComponent>
            {i < links.length - 1 && <Separator />}
          </React.Fragment>
        )
      })}
    </Box>
  )
}
