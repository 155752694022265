import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

const primaryColor = '#016ad4'
const primaryDarkColor = '#0050a1'
const secondaryColor = '#54708c'
const textColor = '#00274d'
const lightGrayColor = '#f5f5f5'

export const usePanelStyles = makeStyles()((theme: Theme) => ({
  panelBox: {
    padding: theme.spacing(2, 4)
  },
  icon: {
    border: 'none',
    boxShadow: 'none',
    color: `${theme.palette.secondary.dark}`,
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
      background: 'transparent'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem'
    }
  },
  sectionHeader: {
    fontSize: '14px',
    lineHeight: '1.5',
    fontWeight: '700',
    color: textColor,
    margin: theme.spacing(2, 0, 0.5)
  },

  panelRow: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  rowIcon: {
    width: 20,
    height: 20,
    color: secondaryColor
  },
  rowText: {
    color: textColor,
    fontSize: 14,
    lineHeight: 1.5,
    '& a': {
      color: primaryColor,
      textDecorationColor: primaryColor
    }
  },
  consentBox: {
    backgroundColor: lightGrayColor,
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2, 1, 0, 2)
  },
  consentHeader: {
    fontSize: 14,
    lineHeight: 1.5,
    fontWeight: 700,
    color: textColor
  },
  consentText: {
    maxHeight: 250,
    overflowY: 'auto',
    color: secondaryColor,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.5,
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    '&::-webkit-scrollbar': {
      width: 7
    },
    '&::-webkit-scrollbar-track': {
      display: 'none'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#e2e2e2',
      borderRadius: 10
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#afafaf'
    }
  },
  consentCheckbox: {
    padding: '0',
    '&.Mui-checked': {
      color: primaryColor
    }
  },
  joinButtonBox: {
    margin: theme.spacing(2, 0, 3)
  },
  joinButton: {
    width: '100%',
    height: 48,
    backgroundColor: primaryColor,

    '&:hover': {
      backgroundColor: primaryDarkColor
    },
    '&:disabled': {
      color: '#ffffff',
      backgroundColor: '#b3d2f2 ',
      '& .MuiSvgIcon-root': {
        color: '#ffffff'
      }
    },
    '& .MuiSvgIcon-root': {
      color: '#ffffff'
    },
    '& .MuiLoadingButton-startIconLoadingStart': {
      opacity: 1
    }
  },
  welcomeBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: theme.spacing(2)
  },
  welcomeImage: {
    margin: theme.spacing(2, 0, 2)
  },
  welcomeHeader: {
    fontSize: 28,
    fontWeight: 500,
    color: textColor
  },
  welcomeText: {
    color: secondaryColor,
    fontSize: 14,
    lineHeight: 1.5,
    fontWeight: 400,
    textAlign: 'center'
  }
}))
