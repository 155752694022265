import React, {useState, useRef, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import MultiSelectDropdown from '../MultiSelectDropdown'

interface ISupplier {
  name: string
  orgUnitIds: string
}
interface SupplierNameFilterType {
  supplierNames: ISupplier[]
  setSelectedOrgUnits: (orgUnitIds: string, selectedList: string[]) => void
  selectedSuppliers: string[]
}

export const supplierNamesCreate = (supplierNames) =>
  supplierNames && supplierNames.map((supplierName) => supplierName.name)

const SupplierNameFilter: React.FC<SupplierNameFilterType> = ({
  supplierNames,
  setSelectedOrgUnits,
  selectedSuppliers
}) => {
  const {t} = useTranslation()
  const suppliersList = useRef(supplierNamesCreate(supplierNames))

  const [selectedOptions, setOptionsList] = useState<string[]>(
    (selectedSuppliers.length && selectedSuppliers) || suppliersList.current
  )

  useEffect(() => {
    setOptionsList((selectedSuppliers.length && selectedSuppliers) || suppliersList.current)
  }, [selectedSuppliers])

  const getSupplierNameFilters = (selectedList: string[]) => {
    // check if old and new are same, if same then return, else continue
    const isListUpdated =
      selectedOptions.length === selectedList.length &&
      selectedOptions.every((value, index) => value === selectedList[index])
    if (isListUpdated) return

    setOptionsList(selectedList)
    const orgUnitIds = selectedList
      .flatMap((option) =>
        supplierNames
          .filter((supplier) => supplier.name === option)
          .map((supplier) => supplier.orgUnitIds)
      )
      .flat()
    setSelectedOrgUnits(orgUnitIds.toString(), selectedList)
  }

  if (supplierNames.length < 2) return null
  return (
    <MultiSelectDropdown
      items={suppliersList.current}
      selectedOptionsList={selectedOptions || selectedSuppliers}
      onClose={getSupplierNameFilters}
      dataTestId="supplier-name-filter-multiselect-dropdown"
      multiSelectLabel={t('supplierNameFilter.supplierNameFilterLabel')}
      selectAllLabel={t('supplierNameFilter.defaultSelectionLabel')}
      translationItemPrefix=""
    />
  )
}

export default SupplierNameFilter
