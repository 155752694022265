import CloseIcon from '@mui/icons-material/Close'
import {Button, Collapse, IconButton} from '@mui/material'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import ThankYouSvg from '../../../Assets/customerFeedbackBanner/thankYou.svg'

import {
  useCustomerFeedbackSurveyAnalytics,
  useCustomerFeedbackSurveyQuestions,
  useCustomerFeedbackSurveyState,
  useCustomerFeedbackSurveyVisibility
} from './hooks'
import {Question} from './Question'
import {useStyles} from './style'
import {CustomerFeedback} from './types'
import {useRoles} from '../../../Roles'

interface CustomerFeedbackSurveyState {
  feedback: CustomerFeedback[]
  currentQuestion: number
  isClosed?: boolean
  isSubmitted?: boolean
}

const autoCloseSeconds = 10

export const CustomerFeedbackSurvey: React.FC<{bottom?: number}> = ({bottom}) => {
  const {classes} = useStyles()
  const {t} = useTranslation()
  const [state, setState] = useState<CustomerFeedbackSurveyState>({
    currentQuestion: 1,
    isClosed: true,
    isSubmitted: false,
    feedback: []
  })
  const [isDismissed, setIsDismissed] = useState(false)
  const {setSurveyStatus} = useCustomerFeedbackSurveyState()
  const {data: roles} = useRoles()
  const analytics = useCustomerFeedbackSurveyAnalytics(roles)
  const questions = useCustomerFeedbackSurveyQuestions()
  const {showSurvey} = useCustomerFeedbackSurveyVisibility(isDismissed)

  useEffect(() => {
    if (state.isClosed && !state.isSubmitted && showSurvey) {
      setTimeout(() => {
        setState((s) => ({...s, isClosed: false}))
        analytics.trackViewed()
      }, 2000)
    }
  }, [state.isClosed, state.isSubmitted, showSurvey, analytics])

  useEffect(() => {
    if (state.currentQuestion > questions.length) {
      submit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentQuestion])

  const setFeedback = (newFeedback: CustomerFeedback) => {
    setState((s) => ({
      ...s,
      feedback: [...state.feedback, newFeedback],
      currentQuestion: state.currentQuestion + 1
    }))
    analytics.trackAnswered(newFeedback.id, newFeedback.sentiment || 0)
  }

  const close = () => setState((s) => ({...s, isClosed: true}))

  const dismiss = () => {
    close()
    setIsDismissed(true)
    analytics.trackDismissed()
    void setSurveyStatus('dismissed')
  }

  const submit = () => {
    setState({...state, isSubmitted: true})
    analytics.trackSubmitted(state.feedback)
    void setSurveyStatus('submitted')
    setTimeout(() => {
      if (!state.isClosed) close()
    }, autoCloseSeconds * 1000)
  }

  if (!showSurvey) return null

  return (
    <div
      className={classes.container}
      style={{bottom: (bottom || 0) - 4}}
      data-test-id="customerFeedbackSurvey"
    >
      <Collapse in={!state.isClosed}>
        <Collapse in={!state.isSubmitted}>
          <div className={classes.header}>
            <IconButton
              size="large"
              onClick={dismiss}
              data-test-id="customerFeedbackSurveyDismissButton"
              className={classes.icon}
            >
              <CloseIcon />
            </IconButton>
            <div>
              {t('customerFeedbackSurvey.questionNumber', {
                currentQuestion: state.currentQuestion,
                numberOfQuestions: questions.length
              })}
            </div>
            <div className={classes.title}>{t('customerFeedbackSurvey.title')}</div>
          </div>
          <div className={classes.divider}>&nbsp;</div>
        </Collapse>
        {questions.map((q, i) => (
          <Collapse in={state.currentQuestion === i + 1} key={q.id}>
            <Question question={q} submit={setFeedback} isLast={i === questions.length - 1} />
          </Collapse>
        ))}
        <Collapse in={state.isSubmitted}>
          <div className={classes.thankYou} data-test-id="customerFeedbackSurveyThankYou">
            <img src={ThankYouSvg} alt={t('customerFeedbackSurvey.thankYou')} />
            <h3>{t('customerFeedbackSurvey.thankYou')}</h3>
            <p>{t('customerFeedbackSurvey.thankYouSummary')}</p>
            <Button
              startIcon={<CloseIcon />}
              variant="text"
              onClick={() => close()}
              data-test-id="customerFeedbackSurveyCloseButton"
            >
              {t('customerFeedbackSurvey.close', {seconds: autoCloseSeconds})}
            </Button>
          </div>
        </Collapse>
      </Collapse>
    </div>
  )
}
