import {Box, Popover} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {ReactComponent as LeftArrowSVG} from '../../../Assets/customerFeedbackBanner/leftArrow.svg'
import {ReactComponent as LetterIconSvg} from '../../../Assets/customerFeedbackBanner/letterIcon.svg'
import {ReactComponent as RightArrowSvg} from '../../../Assets/customerFeedbackBanner/rightArrow.svg'
import {CustomerFeedbackPanel} from '../CustomerFeedbackPanel'
import {useFeedbackProgrammeVisibility, useImprovementProgramme} from '../hooks'

import {useProfileStyles} from './styles'
import {SubscribedPopover} from './SubscribedPopover'

export const CustomerPanelProfile: React.FunctionComponent = () => {
  const {t} = useTranslation()
  const {classes} = useProfileStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>()
  const [isSubscribingPopupInProcess, setIsSubscribingPopupInProcess] = useState<boolean>(false)

  const {data: improvementProgrammeData} = useImprovementProgramme()

  const isSubscribed = improvementProgrammeData?.isSubscribed

  const handleClose = (event: Event | React.SyntheticEvent) => {
    event.stopPropagation()
    setAnchorEl(null)
    isSubscribingPopupInProcess && setIsSubscribingPopupInProcess(false)
  }

  const openPanel = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  if (!useFeedbackProgrammeVisibility()) return null

  return (
    <>
      <Box
        className={classes.profileItem}
        onClick={openPanel}
        data-test-id="feedbackProgramProfileItem"
      >
        <LetterIconSvg className={classes.letterIcon} />
        {t('customerFeedbackPanel.header')}
        {isSubscribed && (
          <span className={classes.subscribedLabel} data-test-id="profileItemSubscriptionLabel">
            {t('customerFeedbackPanel.subscribed')}
          </span>
        )}
        <RightArrowSvg className={classes.rightArrow} />
      </Box>

      <Popover
        disableEnforceFocus
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{paper: classes.paper}}
      >
        <Box
          onClick={handleClose}
          className={classes.backContainer}
          data-test-id="feedbackProgramProfileItemClose"
        >
          <LeftArrowSVG className={classes.letterIcon} />
          <Box onClick={handleClose} className={classes.backTitle}>
            <LetterIconSvg className={classes.letterIcon} /> {t('customerFeedbackPanel.header')}
          </Box>
        </Box>

        {isSubscribed && !isSubscribingPopupInProcess ? (
          <SubscribedPopover close={handleClose} />
        ) : (
          <CustomerFeedbackPanel
            close={handleClose}
            isSubscribingProcessOn={(val) => setIsSubscribingPopupInProcess(val)}
            isFromProfile
            entryPoint="userProfile"
          />
        )}
      </Popover>
    </>
  )
}
