import {Typography} from '@material-ui/core'
import {Box, TableCell, TableHead, TableRow, Tooltip, styled} from '@mui/material'
import {useTranslation} from 'react-i18next'
import InfoIcon from '@mui/icons-material/Info'
import ArtTrackIcon from '@material-ui/icons/ArtTrack'
import {HaulerInfo} from '../declarations/types'
import React from 'react'
import {ShippingType} from '../declarations/OrderIntake.enums'

export const MaterialSectionHeader = styled(Typography)({
  color: '#54708C',
  fontSize: 20,
  fontWeight: 600,
  marginBottom: 16
})

interface MaterialsHeaderProps {
  showHaulierInformation: boolean
  shippingType: ShippingType
}

interface HaulierInforProps {
  showTitle: boolean
  haulierInformation?: HaulerInfo
}

interface ExternalChangeWarningProps {
  text: string
}

export const MaterialsHeader: React.FC<MaterialsHeaderProps> = ({
  showHaulierInformation,
  shippingType
}) => {
  const {t} = useTranslation()

  return (
    <TableHead style={{background: '#F5F6F7'}}>
      <TableRow>
        <TableCell>{t('orderIntake.materialOrder.date')}</TableCell>
        <TableCell>{t('orderIntake.materialOrder.type')}</TableCell>
        <TableCell>{t('orderIntake.materialOrder.load')}</TableCell>
        {shippingType === ShippingType.COLLECT && showHaulierInformation && (
          <TableCell>
            <Box display="inline-flex" alignItems="center">
              <Box>{t('orderIntake.materialOrder.haulierInfo')}</Box>
              <Tooltip title={t('orderIntake.haulierInfoTooltip')}>
                <InfoIcon />
              </Tooltip>
            </Box>
          </TableCell>
        )}
        {shippingType === ShippingType.DELIVER && (
          <TableCell>
            <Box display="inline-flex" alignItems="center">
              <Box>{t('orderIntake.driverInstructions')}</Box>
              <Tooltip title={t('orderIntake.driverInstructionsTooltip')}>
                <InfoIcon />
              </Tooltip>
            </Box>
          </TableCell>
        )}
        <TableCell>
          <Box display="inline-flex" alignItems="center">
            <Box>{t('orderIntake.materialOrder.po')}</Box>
            <Tooltip title={t('orderIntake.materialOrder.poTooltip')}>
              <InfoIcon />
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  )
}

export const HaulierInfo: React.FC<HaulierInforProps> = ({showTitle, haulierInformation}) => {
  const {t} = useTranslation()

  return (
    <>
      {haulierInformation &&
        (haulierInformation.driverName ||
          haulierInformation?.driverPhoneNumber ||
          haulierInformation?.truckLicensePlate ||
          haulierInformation?.trailerLicensePlate) && (
          <Box>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              {showTitle && (
                <>
                  <ArtTrackIcon />
                  <Typography variant="caption">
                    {t('orderIntake.materialOrder.haulierInfo')}
                  </Typography>
                </>
              )}
            </Box>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Typography variant="caption">{haulierInformation?.driverName}</Typography>
              <Typography style={{marginLeft: 8}} variant="caption">
                {haulierInformation?.driverPhoneNumber}
              </Typography>
            </Box>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Typography variant="caption">{haulierInformation?.truckLicensePlate}</Typography>
              {haulierInformation?.trailerLicensePlate && (
                <>
                  <Typography variant="caption">|</Typography>
                  <Typography variant="caption">
                    {haulierInformation?.trailerLicensePlate}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        )}
    </>
  )
}

export const ExternalChangeWarning: React.FC<ExternalChangeWarningProps> = ({text}) => {
  return (
    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
      <InfoIcon color="warning" style={{width: 12, height: 12}} />
      <Typography variant="caption" color="secondary" style={{marginLeft: '4px'}}>
        {text}
      </Typography>
    </Box>
  )
}
