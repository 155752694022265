import {Alert, AlertProps} from '@mui/material'
import React from 'react'

interface Type extends AlertProps {
  type: 'info' | 'success' | 'error'
  message: string
}
export const AlertWithLeftBorder: React.FC<Type> = ({type, message, ...otherProps}) => {
  const borderColor = type === 'success' ? '#00AB50' : type === 'error' ? '#DA0901' : '#54708C'
  return (
    <Alert
      elevation={4}
      icon={false}
      data-test-id={`alert-left-${type}`}
      sx={{
        borderLeft: `4px solid ${borderColor}`,
        borderRadius: 0.5,
        bgcolor: '#FFFFFF',
        color: '#00274D',
        fontSize: 16,
        lineHeight: '22px'
      }}
      {...otherProps}
    >
      {message}
    </Alert>
  )
}
