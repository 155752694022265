import {AxiosResponse} from 'axios'
import {find} from 'lodash'
import queryString from 'query-string'

import {DEFAULT_CUSTOMER} from '../../App.constants'
import {errorKeyFromRequestError} from '../../ErrorMap'
import {AppState} from '../../Root.store'
import {errorsAdd, errorsClear} from '../Errors'
import {createSelectFilter, removeFilterAbstract, setFilterAbstract} from '../Filters'
import {INVOICES, setFilter as setFilterInvoices} from '../Invoices'
import {ORDERS, setFilter as setFilterOrders} from '../Orders'

import {
  CUSTOMER_BY_CUSTOMER_NUMBER_FETCH_FAILURE,
  CUSTOMER_BY_CUSTOMER_NUMBER_FETCH_PENDING,
  CUSTOMER_BY_CUSTOMER_NUMBER_FETCH_SUCCESS,
  CUSTOMER_FILTER,
  CUSTOMER_SET_SEARCH_CUSTOMER,
  CUSTOMER_SET_SEARCH_STRING,
  CustomerActionTypes,
  CUSTOMERS_FETCH_FAILURE,
  CUSTOMERS_FETCH_REQUEST,
  CUSTOMERS_FETCH_RESPONSE,
  REMOVE_CUSTOMER_FILTER,
  SELECT_CUSTOMER_FILTER,
  SET_CUSTOMER_FILTER
} from './Action.types'
import {Customer} from './Customers.types'

export const selectCustomerFilterAbstract = createSelectFilter(CUSTOMER_FILTER)

export const selectCustomerFilter = (customerId: string): CustomerActionTypes => ({
  type: SELECT_CUSTOMER_FILTER,
  customerId
})
export const setCustomerFilter = (customer: Customer): CustomerActionTypes => ({
  type: SET_CUSTOMER_FILTER,
  customer
})
export const removeCustomerFilter = (): CustomerActionTypes => ({
  type: REMOVE_CUSTOMER_FILTER
})

// based on https://stackoverflow.com/questions/40900354/updating-state-managed-by-another-reducer
const setOrdersCustomerFilter = (customer: Customer) =>
  setFilterAbstract(ORDERS, CUSTOMER_FILTER, {...customer})
const setInvoicesCustomerFilter = (customer: Customer) =>
  setFilterAbstract(INVOICES, CUSTOMER_FILTER, {...customer})
export const setGlobalCustomerFilter = (customer: Customer) => (dispatch) => {
  if (customer && customer.customerId) {
    dispatch(setFilterOrders('orgUnitIds', ''))
    dispatch(setFilterInvoices('orgUnitIds', ''))
    dispatch(setFilterOrders('supplierFilterNames', []))
    dispatch(setFilterInvoices('supplierFilterNames', []))
    dispatch(setOrdersCustomerFilter(customer))
    dispatch(setInvoicesCustomerFilter(customer))
    return dispatch(setCustomerFilter(customer))
  }
}

const removeOrdersCustomerFilter = () => removeFilterAbstract(ORDERS, CUSTOMER_FILTER)
const removeInvoicesCustomerFilter = () => removeFilterAbstract(INVOICES, CUSTOMER_FILTER)
export const removeGlobalCustomerFilter = () => (dispatch) => {
  dispatch(removeOrdersCustomerFilter())
  dispatch(removeInvoicesCustomerFilter())
  return dispatch(removeCustomerFilter())
}

// fetchCustomers actions
const fetchCustomersRequest = (): CustomerActionTypes => ({
  type: CUSTOMERS_FETCH_REQUEST
})

const fetchCustomersFailure = (error: any): CustomerActionTypes => ({
  type: CUSTOMERS_FETCH_FAILURE,
  error
})

const fetchCustomersSuccess = (response: Customer[]): CustomerActionTypes => ({
  type: CUSTOMERS_FETCH_RESPONSE,
  response
})

export const fetchCustomers =
  (userId?: string) =>
  async (dispatch, getState, {api}) => {
    dispatch(errorsClear())
    dispatch(fetchCustomersRequest())

    const {customerId: customerIdFromQueryString} = queryString.parse(window.location.search)

    try {
      const response: AxiosResponse<Customer[]> = await api.get('/customers')
      dispatch(fetchCustomersSuccess(response.data))
      const state: AppState = getState()
      const localStorageDefaultCustomerString: string | null = localStorage.getItem(
        `${DEFAULT_CUSTOMER}-${userId}`
      )
      const localStorageDefaultCustomerId: string | null = localStorageDefaultCustomerString
        ? JSON.parse(localStorageDefaultCustomerString).customerId
        : null
      // check if customer is in the customer lists

      // FIXME: move logic to set defaultCustomer to its own action
      const defaultCustomer =
        find(state.customers.customers, {
          customerId: customerIdFromQueryString
            ? (customerIdFromQueryString as string)
            : localStorageDefaultCustomerId || state.customers.customers?.[0]?.customerId || ''
        }) || state.customers.customers?.[0]
      dispatch(setGlobalCustomerFilter(defaultCustomer))

      return response.data
    } catch (err) {
      const errorKey = errorKeyFromRequestError(err)
      dispatch(
        errorsAdd({
          key: 'customers',
          translationKey: `errorMessages.${errorKey}`
        })
      )
      dispatch(fetchCustomersFailure(err))
    }
  }

// fetchCustomerByCustomerNumber actions
const fetchCustomerByCustomerNumberPending = (): CustomerActionTypes => ({
  type: CUSTOMER_BY_CUSTOMER_NUMBER_FETCH_PENDING
})

const fetchCustomerByCustomerNumberFailure = (error: any): CustomerActionTypes => ({
  type: CUSTOMER_BY_CUSTOMER_NUMBER_FETCH_FAILURE,
  error
})

const fetchCustomerByCustomerNumberSuccess = (response: Customer[]): CustomerActionTypes => ({
  type: CUSTOMER_BY_CUSTOMER_NUMBER_FETCH_SUCCESS,
  response
})

export const fetchCustomerByCustomerNumber =
  (
    customerNumber?: string,
    customerName?: string,
    userId?: string,
    customerSearchByName?: boolean
  ) =>
  async (dispatch, getState, {api}) => {
    dispatch(fetchCustomerByCustomerNumberPending())

    if (customerNumber) {
      try {
        const url = customerSearchByName
          ? `/customers/search?searchTerm=${customerName}`
          : `/customers?customerNumber=${customerNumber}`
        const response: AxiosResponse<Customer[]> = await api.get(url)

        const customers = response.data
        dispatch(fetchCustomerByCustomerNumberSuccess(customers))

        if (customers && customers.length < 1) {
          dispatch(removeCustomerFilter())
          localStorage.removeItem(DEFAULT_CUSTOMER)
        }
        if (customers && customers.length === 1) {
          dispatch(
            setCustomerSearchString((customerSearchByName ? customerName : customerNumber) || '')
          )
          dispatch(setGlobalCustomerFilter(response.data[0]))
          if (userId) {
            localStorage.setItem(`${DEFAULT_CUSTOMER}-${userId}`, JSON.stringify(response.data[0]))
            localStorage.removeItem(DEFAULT_CUSTOMER)
          }
        }
        return customers
      } catch (e) {
        dispatch(fetchCustomerByCustomerNumberFailure(e))
      }
    }
  }

export const setCustomerSearchString = (searchString: string): CustomerActionTypes => ({
  type: CUSTOMER_SET_SEARCH_STRING,
  searchString
})

export const setCustomerSearchCustomer = (): CustomerActionTypes => ({
  type: CUSTOMER_SET_SEARCH_CUSTOMER
})
