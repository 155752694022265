import {Typography, dateFormatter, timeFormatter} from '@hconnect/uikit'
import {Box, TableCell, TableRow, makeStyles} from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {OrderRequestStatus} from '../../../OrderIntake/declarations/OrderIntake.enums'
import {DeliveryTime, HaulerInfo, QuantityType} from '../../../OrderIntake/declarations/types'
import ArtTrackIcon from '@material-ui/icons/ArtTrack'
import {OrderStatus} from '../OrderStatus'

const useStyles = makeStyles(() => {
  return {
    errorState: {
      background: 'rgba(218, 9, 1, 0.04)'
    },
    orderRequestMobile: {
      marginTop: 10,
      marginBottom: 10,
      display: 'relative'
    },
    orderStatusMobile: {
      position: 'absolute',
      right: 30
    }
  }
})

interface DeliveryOrderSummaryItemProps {
  isMobile: boolean
  showLoad: boolean
  orderRequestStatus: OrderRequestStatus
  quantity: number
  quantityType: QuantityType
  quantityUom: string
  deliveryDate: string
  deliveryTime: DeliveryTime
  customerReference?: string
  driverInstructions?: string
  haulierInformation?: HaulerInfo
}

export const DeliveryOrderSummaryItem: React.FC<DeliveryOrderSummaryItemProps> = ({
  isMobile,
  showLoad,
  orderRequestStatus,
  quantity,
  quantityUom,
  deliveryDate,
  deliveryTime,
  driverInstructions,
  customerReference,
  haulierInformation
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const c = useStyles()
  const isTimesEqual = deliveryTime.earliest === deliveryTime.latest

  const getOrderStatusClassName = () => {
    switch (orderRequestStatus) {
      case OrderRequestStatus.FAILED:
      case OrderRequestStatus.VALIDATION_FAILED:
        return c.errorState
      default:
        return ''
    }
  }

  const renderHaulierInfo = () => {
    return (
      <>
        {haulierInformation &&
          (haulierInformation.driverName ||
            haulierInformation?.driverPhoneNumber ||
            haulierInformation?.truckLicensePlate ||
            haulierInformation?.trailerLicensePlate) && (
            <Box>
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <ArtTrackIcon />
                <Typography variant="caption">
                  {t('orderIntake.materialOrder.haulierInfo')}
                </Typography>
              </Box>
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <Typography variant="caption">{haulierInformation?.driverName}</Typography>
                <Typography variant="caption">{haulierInformation?.driverPhoneNumber}</Typography>
              </Box>
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <Typography variant="caption">{haulierInformation?.truckLicensePlate}</Typography>
                {haulierInformation?.trailerLicensePlate && (
                  <>
                    <Typography variant="caption">|</Typography>
                    <Typography variant="caption">
                      {haulierInformation?.trailerLicensePlate}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          )}
      </>
    )
  }

  return (
    <>
      {isMobile ? (
        <Box display={'flex'} flexDirection={'column'} className={c.orderRequestMobile}>
          <Box className={c.orderStatusMobile}>
            <OrderStatus status={orderRequestStatus} />
          </Box>
          <Box display={'flex'} flexDirection={'column'} marginBottom={2}>
            <Typography variant="caption">{t('orderIntake.materialOrder.load')}</Typography>
            <Typography variant="body1">{`${quantity} ${quantityUom}`}</Typography>
          </Box>
          <Box display={'flex'} flexDirection={'column'} marginBottom={2}>
            <Typography variant="caption">{t('orderIntake.materialOrder.date')}</Typography>
            <Typography variant="body1">{dateFormatter(deliveryDate, language)}</Typography>
            <Typography variant="body1">
              {isTimesEqual
                ? timeFormatter(moment(deliveryTime.earliest, 'HH:mm:ss'), language)
                : `${timeFormatter(moment(deliveryTime.earliest, 'HH:mm:ss'), language)} -
                  ${timeFormatter(moment(deliveryTime.latest, 'HH:mm:ss'), language)}`}
            </Typography>
          </Box>
          {(driverInstructions || haulierInformation) && (
            <Box display={'flex'} flexDirection={'column'} marginBottom={2}>
              <Typography variant="caption">
                {t('orderIntake.confirmationScreen.additionalInfo')}
              </Typography>
              <Typography variant="body1">{driverInstructions}</Typography>
              {renderHaulierInfo()}
            </Box>
          )}
          {customerReference && (
            <Box display={'flex'} flexDirection={'column'} marginBottom={2}>
              <Typography variant="caption">{t('orderIntake.customerReference')}</Typography>
              <Typography variant="body1">{customerReference}</Typography>
            </Box>
          )}
        </Box>
      ) : (
        <TableRow data-test-id="delivery-order-summary-item" className={getOrderStatusClassName()}>
          <TableCell>
            <OrderStatus status={orderRequestStatus} />
          </TableCell>

          {showLoad && (
            <TableCell>
              <Typography variant="body2">{`${quantity} ${quantityUom}`}</Typography>
            </TableCell>
          )}

          <TableCell>
            <Typography variant="body2">{dateFormatter(deliveryDate, language)}</Typography>
          </TableCell>

          <TableCell>
            <Typography variant="body2">
              {isTimesEqual
                ? timeFormatter(moment(deliveryTime.earliest, 'HH:mm:ss'), language)
                : `${timeFormatter(moment(deliveryTime.earliest, 'HH:mm:ss'), language)} -
                  ${timeFormatter(moment(deliveryTime.latest, 'HH:mm:ss'), language)}`}
            </Typography>
          </TableCell>

          <TableCell>
            <Typography variant="body2">{driverInstructions}</Typography>
            {renderHaulierInfo()}
          </TableCell>

          <TableCell>
            <Typography variant="body2">{customerReference}</Typography>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
