/* eslint-disable react-hooks/rules-of-hooks */
import {first, identity, sortBy} from 'lodash'
import {groupBy, flow, mapValues} from 'lodash/fp'

import {BusinessLineType, Dictionary} from '../../common/types'
import {useBulkCementOrderIntake} from '../../Organisms/OrderIntake/BulkCementOrderIntake.provider'
import {
  availableDateRange,
  earliestAvailableDate,
  getDayCode,
  getLeadTimeForDay,
  prepareDateRange
} from '../../Organisms/OrderIntake/utils'
import {getMaterialOptionsByInvalidity} from '../BulkCement/utils'
import {
  DATA_BASE_TIME_FORMAT,
  evaluateDeliveryTime
} from '../components/TimeScroller/TimeScroller.utils'
import {
  BUSINESS_HOURS_MIN_INTERVAL_DEFAULT,
  DEFAULT_CUSTOMER_REFERENCE
} from '../declarations/constants'
import {ShippingType} from '../declarations/OrderIntake.enums'
import {
  ConfigurableTimeSlot,
  OrderIntakeMaterialOptionPayload,
  OrderIntakeOption,
  OrderIntakeOptions,
  OrderRequest
} from '../declarations/types'

export type ErrorCodesForOption =
  | 'initialDeliveryDateNotAvailable'
  | 'initialDeliveryTimeNotAvailable'
  | 'OptionCannotBeEmpty'
  | 'NoDefaultMaterialFound'

export function invariant(condition: unknown, message: ErrorCodesForOption): asserts condition {
  if (!condition) throw new Error(message)
}

export const groupBySiteNumber = groupBy<OrderIntakeMaterialOptionPayload>(
  (o) => o.shippingAddress.siteNumber
)
export const groupByMaterial = groupBy<OrderIntakeMaterialOptionPayload>(
  (o) => o.material.materialEnteredNumber
)

export const mapFromSiteGroup = mapValues(
  (value: OrderIntakeMaterialOptionPayload[]): OrderIntakeOption | undefined => {
    if (value.length === 0) return undefined
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-unused-vars
    const {shippingAddress, contact, canDisableEmailSending, plant, driverInstructions} =
      first(value)!
    return {
      shippingAddress,
      contact,
      materials: groupByMaterial(value),
      canDisableEmailSending,
      driverInstructions
    }
  }
)

function getDefaultOrderRequest(): Partial<OrderRequest> {
  return {
    isDateNotAvailable: false,
    isDateChange: false,
    isMaterialHighlighted: false,
    initialFullName: ''
  }
}

interface PartialApply<T> {
  (o: Partial<T>): Partial<T>
}

const getDefaultOption = (options: OrderIntakeOptions) => first(Object.values(options))

export const getDefaultMaterials = (option: OrderIntakeOption) =>
  first(sortBy(option.materials, Object.values(option.materials)))

export const getValidMaterials = (option: OrderIntakeOption) =>
  getMaterialOptionsByInvalidity(option.materials, false)

export const getDefaultMaterial = (option: OrderIntakeOption) => {
  return first(getValidMaterials(option)) || first(getDefaultMaterials(option))
}

const mergePartials =
  <T>(p: Partial<T>): PartialApply<T> =>
  (o) => ({...o, ...p})

const applyInitialDeliveryDate = (
  materials: Dictionary<OrderIntakeMaterialOptionPayload[]>,
  defaultMaterials: OrderIntakeMaterialOptionPayload[],
  shippingType: ShippingType,
  configuration?: Configuration
): PartialApply<OrderRequest> => {
  const {slotConfiguration} = useBulkCementOrderIntake()
  const businessHours = defaultMaterials[0].businessHours

  const defaultOptionDateRange = prepareDateRange(
    defaultMaterials.map((item) => item.dateRange),
    businessHours,
    materials,
    slotConfiguration
  )

  let initialDeliveryDate = earliestAvailableDate(
    defaultOptionDateRange,
    defaultMaterials[0]?.businessHours.timeZone
  )

  if (shippingType === ShippingType.COLLECT && configuration?.nextDayCollect) {
    const dayCode = getDayCode(initialDeliveryDate)
    const leadTime = getLeadTimeForDay(dayCode, defaultOptionDateRange)

    if (leadTime === 0) {
      const availableDateRangeArray = availableDateRange(
        defaultOptionDateRange,
        defaultMaterials[0]?.businessHours.timeZone
      )
      const sortedAvailableDateRange = availableDateRangeArray.sort()
      initialDeliveryDate =
        sortedAvailableDateRange.length > 1
          ? sortedAvailableDateRange[1]
          : sortedAvailableDateRange[0]
      return mergePartials({initialDeliveryDate})
    }
  }

  return mergePartials({initialDeliveryDate})
}

const applyDefaultMaterial = (m: OrderIntakeMaterialOptionPayload): PartialApply<OrderRequest> =>
  mergePartials({
    initialDeliveryTime: {
      earliest: m.defaultDeliveryWindow.defaultEarliestLoadTime,
      latest: m.defaultDeliveryWindow.defaultLatestLoadTime
    },
    initialTruckQuantity: m.truckCapacity.capacity
  })

const processTimeSlots =
  (
    defaultMaterial: OrderIntakeMaterialOptionPayload,
    slots?: ConfigurableTimeSlot[]
  ): PartialApply<OrderRequest> =>
  (or) => {
    if (or.payload === undefined || slots === undefined) return or

    const initialDeliveryDate = or.payload.deliveryDate
    const timeSlotsForDate = slots?.filter((s) => s.date === initialDeliveryDate)

    if (timeSlotsForDate.length === 0) return or

    const {slotConfiguration} = useBulkCementOrderIntake()
    const businessHours = defaultMaterial.businessHours
    const minInterval = businessHours?.minInterval ?? BUSINESS_HOURS_MIN_INTERVAL_DEFAULT

    const evaluateArgs = [
      minInterval,
      DATA_BASE_TIME_FORMAT,
      initialDeliveryDate,
      defaultMaterial.defaultDeliveryWindow,
      defaultMaterial.businessHours,
      defaultMaterial.businessHours.timeZone,
      false
    ] as const

    const deliveryTime = evaluateDeliveryTime(...evaluateArgs, slotConfiguration, timeSlotsForDate)

    return {
      ...or,
      payload: {
        ...or.payload,
        deliveryTime: deliveryTime
      }
    }
  }

const applyPayload =
  (o: OrderIntakeOption, m: OrderIntakeMaterialOptionPayload): PartialApply<OrderRequest> =>
  (or) => {
    invariant(or.initialDeliveryDate !== undefined, 'initialDeliveryDateNotAvailable')
    invariant(or.initialDeliveryTime !== undefined, 'initialDeliveryTimeNotAvailable')
    return {
      ...or,
      payload: {
        isSendingConfirmationEmailUnChecked: false,
        siteNumber: o.shippingAddress.siteNumber,
        contact: o.contact,
        deliveryDate: or.initialDeliveryDate,
        deliveryTime: or.initialDeliveryTime,
        materialNumber: m.material.materialNumber,
        materialDescription: m.material.materialDescription,
        materialEnteredNumber: m.material.materialEnteredNumber,
        contractNumber: m.material.contractNumber,
        contractItemPositionNumber: m.material.contractItemPositionNumber,
        customerReference:
          (m.customerReference === '' ? undefined : m.customerReference) ??
          DEFAULT_CUSTOMER_REFERENCE,
        shippingType: m.shippingType,
        capacity: {
          quantity: m.truckCapacity.capacity,
          quantityType: m.material.quantityType
        },
        customerId: m.customerNumber,
        businessLineCode: m.material.businessLine,
        plantName: m.plant.plantName,
        plantNumber: m.plant.plantNumber
      }
    }
  }

export type Configuration = {
  isSlotsManagementEnabled: boolean
  nextDayCollect: boolean
  applyMaterialEnteredDescription: boolean
  applyContractItemDescription: boolean
}

export type CreateDefaultOrderRequest = (
  shippingType: ShippingType,
  options: OrderIntakeOptions,
  configuration?: Configuration
) => OrderRequest
export const createDefaultOrderRequest: CreateDefaultOrderRequest = (
  shippingType,
  options,
  configuration
) => {
  const option = getDefaultOption(options)
  invariant(option !== undefined, 'OptionCannotBeEmpty')
  const defaultMaterials = getDefaultMaterials(option)
  const defaultMaterial = getDefaultMaterial(option)
  invariant(defaultMaterials !== undefined, 'NoDefaultMaterialFound')
  invariant(defaultMaterial !== undefined, 'NoDefaultMaterialFound')
  return flow([
    getDefaultOrderRequest,
    applyInitialDeliveryDate(option.materials, defaultMaterials, shippingType, configuration),
    applyDefaultMaterial(defaultMaterial),
    applyPayload(option, defaultMaterial),
    configuration?.isSlotsManagementEnabled
      ? processTimeSlots(defaultMaterial, defaultMaterials[0].plant.configurableSlots)
      : identity
  ])()
}

export type FromOptionPayload = (options: OrderIntakeMaterialOptionPayload[]) => OrderIntakeOptions
export const fromOptionPayload: FromOptionPayload = flow([groupBySiteNumber, mapFromSiteGroup])

export const getBusinessLine = (options?: OrderIntakeOptions) => {
  if (options === undefined) return BusinessLineType.CEM
  const option = getDefaultOption(options)
  invariant(option !== undefined, 'OptionCannotBeEmpty')
  return getDefaultMaterial(option)?.material.businessLine ?? BusinessLineType.CEM
}
