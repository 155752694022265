import {AxiosError} from 'axios'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {BusinessLineType} from '../../../common/types'
import {
  OrderIntakePayload,
  OrderRequestGroupResponse,
  OrderRequestResponse
} from '../../../OrderIntake/declarations/types'
import {ProcessingOrderCard} from '../ProcessingOrderCard'

import {DeliveryOrderSummaryItem} from './DeliveryOrderSummaryItem'
import {ShippingType} from '../../declarations/OrderIntake.enums'

interface Props {
  isMobile: boolean
  showLoad: boolean
  showTime: boolean
  delivery: OrderIntakePayload
  quantityUom: string
  itemOrder: string
  businessLine: BusinessLineType
  onError: (error: AxiosError) => void
  customErrorMessage?: string
  groupedOrderSummary: OrderRequestGroupResponse | undefined
  isLoading: boolean
  isError: boolean
}

export const DeliveryProcessingOrderCard: React.FC<Props> = ({
  isMobile,
  showLoad,
  showTime,
  delivery,
  quantityUom,
  groupedOrderSummary,
  isLoading,
  onError
}) => {
  const {t} = useTranslation()
  const [orderSummaryData, setOrderSummaryData] = React.useState<OrderRequestResponse | null>(null)
  const [showError, setShowError] = React.useState(false)

  useEffect(() => {
    if (groupedOrderSummary && groupedOrderSummary.isSuccess) {
      setShowError(false)
      setOrderSummaryData(groupedOrderSummary.result as OrderRequestResponse)
    } else if (groupedOrderSummary && !groupedOrderSummary.isSuccess) {
      setShowError(true)
      onError(groupedOrderSummary.result as AxiosError)
    }
  }, [delivery])

  return (
    <>
      {showError ? (
        <ProcessingOrderCard
          shippingType={ShippingType.DELIVER}
          isLoading={isLoading}
          isMobile={isMobile}
          showLoad={showLoad}
          showTime={showTime}
          loadingLabel={t('orderIntake.processingOrder')}
          date={delivery.deliveryDate}
          time={delivery.deliveryTime}
          quantity={delivery.capacity.quantity}
          quantityType={delivery.capacity.quantityType}
          quantityUom={quantityUom}
          haulierInformation={delivery.haulerInfo}
          customerReference={delivery.customerReference}
          driverInstructions={delivery.additionalDriverInfo?.driverInstructions}
          hasError={showError}
        />
      ) : orderSummaryData ? (
        <DeliveryOrderSummaryItem
          isMobile={isMobile}
          showLoad={showLoad}
          orderRequestStatus={orderSummaryData.status}
          quantity={orderSummaryData.requestData.quantity}
          quantityType={delivery.capacity.quantityType}
          quantityUom={orderSummaryData.requestData.quantityUom}
          deliveryDate={orderSummaryData.requestData.deliveryDate}
          deliveryTime={orderSummaryData.requestData.deliveryTime}
          driverInstructions={orderSummaryData.requestData.additionalDriverInfo.driverInstructions}
          customerReference={orderSummaryData.requestData.customerReference}
          haulierInformation={orderSummaryData.requestData.haulerInfo}
        />
      ) : null}
    </>
  )
}
