import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'

import {api} from '../../App.global'

export enum MessageType {
  Information = 'information',
  Warning = 'warning'
}

export interface AnnouncementMessage {
  messageId: number
  message: string
  messageType: MessageType
  isActive: boolean
}

interface ApiResponse {
  announcements: AnnouncementMessage[]
}
interface ErrorResponse {
  error: Error
}

export const useAnnouncementMessages = (product: string) => {
  return useQuery<ApiResponse, ErrorResponse>(['announcements'], async () => {
    try {
      const res: AxiosResponse<AnnouncementMessage[]> = await api.get(
        `/announcements/messages?product=${product}`
      )
      return {
        announcements: res.data
      }
    } catch (e) {
      throw new Error('Error during fetching announcement messages')
    }
  })
}
