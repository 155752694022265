import {Button, Snackbar, SnackbarContent} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'

const styles = () => ({
  snackbar: {
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
    color: 'rgba(255, 255, 255, 0.67)',
    height: 52,
    fontSize: 14
  },
  action: {
    color: 'rgb(41, 170, 255)',
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: '1.2px',
    textTransform: 'uppercase',
    height: 36,
    padding: 8,
    '&:hover': {
      backgroundColor: 'rgba(41, 170, 255, 0.2)'
    }
  }
})

function MySnackbarContent(props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {classes, className, message, onClose, variant, action, actionLabel, ...other} = props

  return (
    <SnackbarContent
      data-test-id="error-snackbar-content"
      className={classNames(className.snackbar)}
      aria-describedby="client-snackbar"
      message={<span id="client-snackbar">{message}</span>}
      action={[
        <TextButton
          key="snackbar-action-0"
          data-test-id="error-snackbar-btn"
          actionLabel={actionLabel}
          className={className.action}
          action={action}
        />
      ]}
      {...other}
    />
  )
}

function TextButton({className, action, actionLabel}) {
  // TODO@dev quick fix for stop bubbling effect in the data table
  const handleClick = (event) => {
    event.stopPropagation()

    try {
      action()
    } catch (err) {
      console.log(action)
      console.log(err)
    }
  }
  return (
    <Button variant="text" className={className} onClick={handleClick}>
      {actionLabel}
    </Button>
  )
}

const RequestErrorSnackbar = ({error, classes, onClose = () => {}}) => {
  const {t} = useTranslation()

  return (
    <Snackbar
      autoHideDuration={6000}
      anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
      data-test-id="error-snackbar"
      open={!!error}
      onClose={() => {
        onClose()
      }}
    >
      {error && (
        <MySnackbarContent
          className={classes}
          message={t(error.translationKey)}
          action={error.callback}
          actionLabel={t(error.callbackTranslationKey)}
        />
      )}
    </Snackbar>
  )
}

RequestErrorSnackbar.propTypes = {
  error: PropTypes.any,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(RequestErrorSnackbar)
