import {Typography, dateFormatter} from '@hconnect/uikit'
import {Box, Button} from '@material-ui/core'
import currencyFormatter from 'currency-formatter'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {PermissionTypes, usePermissions} from '../../../Permissions'
import {Features, useFeaturesState} from '../../Features'
import {Payment} from '../../Payments'
import {InfoBracketContainer} from '../components'
import {
  CashBalanceProgressBarContainer,
  CashBalanceProgressBarItem
} from '../components/CashBalanceProgressBar'

import {NotYetInvoicedCashBalance} from './CashBalance.types'
import {CashInfoBox} from './components/CashInfoBox'

interface MostRecentPaymentProps {
  payments?: Payment[]
  company: string
}

const MostRecentPayment: React.FC<MostRecentPaymentProps> = ({company, payments}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const lastPaymentToCompanyByPayer = payments?.find((payment) => payment.companyCode === company)
  const {getFeature} = useFeaturesState()
  const isLastPaymentAllowed = getFeature('CashBalanceLastPayment')

  if (!isLastPaymentAllowed) {
    return null
  }

  if (!lastPaymentToCompanyByPayer) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        data-test-id="cash-balance-no-last-payment"
      >
        <Typography customVariant="formLabel" customColor="textSecondary">
          {t('overview.cashBalance.noRecentPayment', {days: 30})}
        </Typography>
      </Box>
    )
  }
  const {postingDate, amount, currency} = lastPaymentToCompanyByPayer
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      data-test-id="cash-balance-last-payment"
    >
      <Typography customVariant="formLabel" customColor="textSecondarySoft">
        {t('overview.cashBalance.mostRecent')}
      </Typography>
      <Box m={0.5} />
      <Typography customVariant="formLabel" color="textSecondary">
        {`${dateFormatter(postingDate, language)}, ${currencyFormatter.format(amount, {
          code: currency,
          locale: language
        })}`}
      </Typography>
    </Box>
  )
}

interface CashBalanceProps {
  creditLimit: number
  deposit: number
  notYetInvoicedAmount: number
  deliveredNotBilled?: number
  orderedNotDelivered?: number
  openAmount: number
  currency: string
  creditExposure: number
  remainingCredit: number
  payments?: Payment[]
  company: string
  minified?: boolean
  total: number
  exceededLimit: number
}

export const CashBalance: React.FC<CashBalanceProps> = (props: CashBalanceProps) => {
  const {
    creditLimit,
    deposit,
    remainingCredit,
    notYetInvoicedAmount,
    deliveredNotBilled = 0,
    orderedNotDelivered = 0,
    openAmount,
    currency,
    payments,
    company,
    minified,
    total,
    exceededLimit
  } = props

  const {t} = useTranslation()
  const history = useHistory<History>()
  const {grantedPermissionTypes} = usePermissions()
  const {getFeature} = useFeaturesState()
  const paymentsAllowed = grantedPermissionTypes.has(PermissionTypes.CHANGE_FINANCE)
  const isLastPaymentAllowed = getFeature('CashBalanceLastPayment')
  const isSplittedValueEnabled = getFeature('CashBalanceDetailedNotYetInvoiced')
  const hasCredit = creditLimit !== 0
  // const exceededLimitAmount = (creditLimit - creditExposure) * -1
  // let total = deposit + creditLimit
  let isOverDrawn = false

  const notYetInvoiced: NotYetInvoicedCashBalance = isSplittedValueEnabled
    ? {
        amount: deliveredNotBilled,
        subtitle: t('overview.cashBalance.dispatched')
      }
    : {
        amount: notYetInvoicedAmount,
        subtitle: null
      }
  const notYetInvoicedNotYetDispatched: NotYetInvoicedCashBalance | null = isSplittedValueEnabled
    ? {
        amount: orderedNotDelivered,
        subtitle: t('overview.cashBalance.notYetDispatched')
      }
    : null

  if (exceededLimit > 0) {
    // total += exceededLimit
    isOverDrawn = true
  }
  return (
    <Box display="flex" flexDirection="column">
      <Box p={minified ? 0 : 3} />
      {hasCredit ? (
        <>
          <InfoBracketContainer
            creditLimit={creditLimit}
            openAmount={openAmount}
            deposit={deposit}
            overDraft={exceededLimit}
            currency={currency}
            minified={minified}
          />
          <Box p={0.25} />
        </>
      ) : null}
      <CashBalanceProgressBarContainer minified={minified}>
        <CashBalanceProgressBarItem
          variant="unpaid"
          amount={openAmount}
          totalAmount={total}
          minified={minified}
        />
        <CashBalanceProgressBarItem
          dataTestId="not-yet-invoiced-progress-bar"
          variant="notYetInvoiced"
          amount={notYetInvoiced.amount}
          totalAmount={total}
          minified={minified}
        />
        {notYetInvoicedNotYetDispatched ? (
          <CashBalanceProgressBarItem
            dataTestId="not-yet-invoiced-not-yet-dispatched-progress-bar"
            variant="notYetInvoicedNotYetDispatched"
            amount={notYetInvoicedNotYetDispatched.amount}
            totalAmount={total}
            minified={minified}
          />
        ) : null}
      </CashBalanceProgressBarContainer>
      <Box p={minified ? 1 : 2.75} />
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column">
          {!minified ? (
            <Box display="flex" mb={2}>
              <MostRecentPayment payments={payments} company={company} />
            </Box>
          ) : null}
          <Box
            display="flex"
            flexDirection="row"
            justifyContent={isLastPaymentAllowed ? 'flex-start' : 'flex-end'}
          >
            <CashInfoBox
              variant="unpaid"
              amount={openAmount}
              currency={currency}
              minified={minified}
            />
            <Box paddingLeft={4} />
            <CashInfoBox
              variant="notYetInvoiced"
              amount={notYetInvoiced.amount}
              currency={currency}
              minified={minified}
              subtitle={notYetInvoiced.subtitle}
              dataTestIdSubtitle="not-yet-invoiced-subtitle"
              dataTestId="not-yet-invoiced-cash-info-box"
            />
            <Box paddingLeft={4} />
            {notYetInvoicedNotYetDispatched ? (
              <>
                <CashInfoBox
                  variant="notYetInvoicedNotYetDispatched"
                  amount={notYetInvoicedNotYetDispatched.amount}
                  currency={currency}
                  minified={minified}
                  subtitle={notYetInvoicedNotYetDispatched.subtitle}
                  dataTestIdSubtitle="not-yet-invoiced-not-yet-dispatched-subtitle"
                  dataTestId="not-yet-invoiced-not-yet-dispatched-cash-info-box"
                />
                <Box paddingLeft={4} />
              </>
            ) : null}
            <CashInfoBox
              variant="creditLeft"
              amount={remainingCredit}
              currency={currency}
              minified={minified}
              isOverDrawn={isOverDrawn}
              overdraft={exceededLimit}
              hasDeposit={deposit > 0}
            />
          </Box>
        </Box>
        {!minified && paymentsAllowed ? (
          <Features name="PayAgainstAccount">
            <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={1.5}>
              <Button
                data-test-id="pay-now-button"
                onClick={() => history.push('/finance/paymentRequest')}
                color="primary"
              >
                {t('lumpSumPayment.makeLumpSumPayment')}
              </Button>
            </Box>
          </Features>
        ) : null}
      </Box>
    </Box>
  )
}
