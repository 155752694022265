import {LinearProgress, LinearProgressProps, makeStyles} from '@material-ui/core'
import {lighten} from '@material-ui/core/styles'
import React from 'react'

const useProgressBarStyles = makeStyles({
  root: {
    height: 6,
    backgroundColor: lighten('#31ca72', 0.75)
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#31ca72'
  }
})

const MiniProgressBar: React.FC<LinearProgressProps> = (props: LinearProgressProps) => {
  const {root, bar} = useProgressBarStyles()
  return (
    <LinearProgress
      variant="determinate"
      classes={{
        root,
        bar
      }}
      {...props}
    />
  )
}

export default MiniProgressBar
