import createCache from '@emotion/cache'
import {CacheProvider} from '@emotion/react'
import {ApiProvider} from '@hconnect/common/hooks/useApi'
import {HCTheme, History} from '@hconnect/uikit'
import {OreoContextProvider} from '@hconnect/uikit/src/lib/Oreo/components/OreoContext'
import {overrideHConnectTheme} from '@hconnect/uikit/src/lib2'
import {CircularProgress, createTheme, CssBaseline} from '@material-ui/core'
import {ThemeProvider} from '@material-ui/styles'
import {CssBaseline as CssBaselineMui} from '@mui/material'
import {Theme, ThemeProvider as ThemeProviderMui} from '@mui/material/styles'
import {ConnectedRouter} from 'connected-react-router'
import 'core-js/stable'
import i18next from 'i18next'
import {identity} from 'lodash'
import React, {Suspense} from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import {HelmetProvider} from 'react-helmet-async'
import {I18nextProvider} from 'react-i18next'
import {ReactQueryDevtools} from 'react-query/devtools'
import {Provider as ReactReduxProvider} from 'react-redux'
import 'regenerator-runtime/runtime'

import {api, publicApi} from './App.global'
import {QueryProvider} from './common/react-query/QueryProvider'
import Head from './meta/Head'
import {BrandingProvider} from './Organisms/Branding'
import {DocumentsDownloadProvider} from './Organisms/Documents'
import {FeaturesProvider} from './Organisms/Features'
import {MaintenanceScreenProvider} from './Pages/MaintenanceScreen/MaintenanceScreenProvider'
import {store} from './Root.store'

import {AuthProvider} from '@hconnect/common/authentication/context'

export const muiCache = createCache({
  key: 'mui'
})

const customBreakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1280,
    xl: 1920
  }
}

// Note: for unknown reason in unit test overrideDefaultTheme is undefined
// therefore it will be defaulted to identity function
const hubTheme = (overrideHConnectTheme ?? identity)({
  palette: {
    primary: {
      main: '#29aaff'
    }
  },
  typography: {
    caption: {
      opacity: 1
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        root: ({theme}: {theme: Theme}) => ({
          body: {
            background:
              'linear-gradient(90deg, rgba(0,55,77,1) 0%, rgba(0,99,138,1) 40%,rgba(0,99,138,1) 60%, rgba(0,55,77,1) 100%)'
          },
          'body *::-webkit-scrollbar': {
            width: '8px',
            height: '8px'
          },
          'body *::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 4px rgba(0,0,0,0.3)',
            backgroundColor: theme.palette.primary.dark
          },
          'body *::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.main
          }
        })
      }
    }
  },
  breakpoints: customBreakpoints
})

const customHCTheme = createTheme({
  ...HCTheme,
  palette: {
    ...HCTheme.palette,
    error: {
      main: '#d32f2f',
      light: '#f44336',
      dark: '#b71c1c',
      contrastText: '#ffffff'
    }
  },
  breakpoints: customBreakpoints
})

export const Provider = ({children}: {children: React.ReactNode}) => (
  <Suspense fallback={<CircularProgress data-test-id="init-fallback" />}>
    <ApiProvider secureApi={api} publicApi={publicApi}>
      <HelmetProvider>
        <Head />
        <OreoContextProvider>
          <I18nextProvider i18n={i18next}>
            <ThemeProvider theme={customHCTheme}>
              <CssBaseline />
              <ThemeProviderMui theme={hubTheme}>
                <CssBaselineMui />
                <CacheProvider value={muiCache}>
                  <QueryProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                    <ReactReduxProvider store={store}>
                      <ConnectedRouter history={History}>
                        <BrandingProvider>
                          <DocumentsDownloadProvider>
                            <MaintenanceScreenProvider>
                              <AuthProvider>
                                <FeaturesProvider>{children}</FeaturesProvider>
                              </AuthProvider>
                            </MaintenanceScreenProvider>
                          </DocumentsDownloadProvider>
                        </BrandingProvider>
                      </ConnectedRouter>
                    </ReactReduxProvider>
                  </QueryProvider>
                </CacheProvider>
              </ThemeProviderMui>
            </ThemeProvider>
          </I18nextProvider>
        </OreoContextProvider>
      </HelmetProvider>
    </ApiProvider>
  </Suspense>
)
